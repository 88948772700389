import { themeColorValues } from '@/contentful/utils/commonValues'
import { defineDocument, defineObject } from 'sanity-typed-queries'
import { productVariant } from './productVariant'
import { JsonType } from './JsonType'
import type { SectionTypeStub } from './section'
import { textItem } from './textItem'

const globalDataFields = defineObject(
  'globalDataFields',
  {
    reference: { type: 'string' },
    trustpilotScore: { type: 'number' },
    trustpilotRatingCount: { type: 'number' },
    reviewSnippetProduct: { type: 'number' },
    reviewSnippetDescription: { type: 'string' },
    footerSubscribeFormContent: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'section' }] }],
    },
    blogMiniBannerContent: { type: 'json' },
    cartProductSuggestions: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'productVariant' }] }],
    },
    cartUsps: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'textItem' }] }],
    },
    cartUpsellBanner: { type: 'string' },
    miniBannerContentBackgroundColor: {
      type: 'string',
      options: { list: themeColorValues },
    },
    miniBannerContentForegroundColor: {
      type: 'string',
      options: { list: themeColorValues },
    },
    tolstoyExperimentId: {
      type: 'string',
    },
  },
  [
    {} as JsonType,
    {} as SectionTypeStub,
    productVariant.productVariant,
    textItem.textItem,
  ]
)

export const globalData = defineDocument(
  'globalData',
  {
    fields: {
      type: 'globalDataFields',
    },
  },
  [globalDataFields.globalDataFields]
)
