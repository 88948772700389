import { decodeShopifyId } from '@/utils/shopify'

export type DatahappyEcommerceData = {
  currencyCode?: string
  value?: number
  detail?: {
    products?: {
      productId?: string | number
      variantId?: string | number
      productName?: string
      variantName?: string
      name?: string
      price?: number
      quantity?: number
    }[]
  }
}

const transformEcommerceDataProducts = (input: DatahappyEcommerceData) => {
  const transformed =
    input.detail?.products?.map((product) => ({
      affiliation: 'Shopify',
      item_brand: 'Heights',
      item_name: product.productName ?? product.name,
      item_variant: product.variantName,
      price: product.price,
      quantity: product.quantity ?? 1,
      ...(typeof product.variantId !== 'undefined'
        ? { item_id: String(decodeShopifyId(product.variantId)) }
        : {}),
    })) ?? []
  return transformed
}

const proxy = {
  trackPageview(input?: {
    pageName: string
    pageCategory?: string
    eventProperties?: Record<string, unknown>
  }) {
    window.datahappy?.trackPageview(input)
  },
  trackAddToCart(
    ecommerceInfo: DatahappyEcommerceData,
    input?: {
      eventProperties?: Record<string, unknown>
      userTraits?: Record<string, unknown>
    }
  ) {
    window.datahappy?.trackAddToCart({
      eventProperties: {
        ...input?.eventProperties,
        value: ecommerceInfo.value,
        currency: ecommerceInfo.currencyCode,
        items: transformEcommerceDataProducts(ecommerceInfo),
      },
      userTraits: input?.userTraits,
    })
  },
  trackBeginCheckout(
    ecommerceInfo: DatahappyEcommerceData,
    input?: {
      eventProperties?: Record<string, unknown>
      userTraits?: Record<string, unknown>
    }
  ) {
    window.datahappy?.trackBeginCheckout({
      eventProperties: {
        ...input?.eventProperties,
        value: ecommerceInfo.value,
        currency: ecommerceInfo.currencyCode,
        items: transformEcommerceDataProducts(ecommerceInfo),
      },
      userTraits: input?.userTraits,
    })
  },
  trackViewItem(
    ecommerceInfo: DatahappyEcommerceData,
    input?: {
      eventProperties?: Record<string, unknown>
      userTraits?: Record<string, unknown>
    }
  ) {
    console.info(
      'trackViewItem',
      ecommerceInfo,
      window.datahappy,
      window.datahappy?.config,
      window.datahappy?.initialized
    )
    window.datahappy?.trackViewItem({
      eventProperties: {
        ...input?.eventProperties,
        value: ecommerceInfo.value,
        currency: ecommerceInfo.currencyCode,
        items: transformEcommerceDataProducts(ecommerceInfo),
      },
      userTraits: input?.userTraits,
    })
  },
  trackLeadCreation(input?: {
    eventProperties?: Record<string, unknown>
    userTraits?: Record<string, unknown>
  }) {
    window.datahappy?.trackLeadCreation({
      eventProperties: {
        ...input?.eventProperties,
        value: input?.eventProperties?.value ?? 1.5,
        currency: input?.eventProperties?.currency ?? 'GBP',
      },
      userTraits: input?.userTraits,
    })
  },
}

export const useDatahappyValue = [proxy]

export const useDatahappy = () => {
  return useDatahappyValue
}
