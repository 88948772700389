import React from 'react'
import Image from 'next/image'
import { ImageProps } from '@heights/heights-ui-library'
import { useDataUrl } from '@/hooks'
import { twMerge } from '@heights/heights-theme'

// @README layout is now deprecated

export const ImageComponent: React.FC<ImageProps> = (props) => {
  const { sizes, objectFit, objectPosition, className } = props
  const blurDataUrlReader = useDataUrl(
    'image' in props ? props.image.placeholder?.fallback : null
  )
  const placeholderData = blurDataUrlReader()
  const mergedClassName = twMerge(
    'text-[0px] block max-w-full h-auto',
    objectFit === 'contain' && 'object-contain',
    objectFit === 'cover' && 'object-cover',
    objectFit === 'fill' && 'object-fill',
    objectFit === 'none' && 'object-none',
    className
  )

  if ('image' in props) {
    const { image, alt, fullscreen, loading, style } = props

    return (
      <Image
        className={mergedClassName}
        style={{
          backgroundColor: placeholderData?.[0] ?? undefined,
          objectPosition,
          ...style,
        }}
        alt={alt ?? ''}
        fill={fullscreen}
        priority={loading === 'eager'}
        width={!fullscreen ? image.width : undefined}
        height={!fullscreen ? image.height : undefined}
        sizes={sizes || image.images.fallback?.sizes}
        placeholder={placeholderData?.[1] ? 'blur' : 'empty'}
        blurDataURL={placeholderData?.[1] ?? undefined}
        src={image.images.fallback?.src ?? ''}
      />
    )
  }

  if ('src' in props) {
    const { src, width, height, alt, fullscreen, style, loading } = props
    return (
      <Image
        className={mergedClassName}
        style={{
          backgroundColor: placeholderData?.[0] ?? undefined,
          objectPosition,
          ...style,
        }}
        fill={fullscreen}
        alt={alt ?? ''}
        priority={loading === 'eager'}
        sizes={sizes}
        width={!fullscreen ? Number(width ?? 100) : undefined}
        height={!fullscreen ? Number(height ?? 100) : undefined}
        src={src}
      />
    )
  }
  return null
}
