import nookies from 'nookies'
import { signal } from '@preact/signals-react'

type CookiesMap = Record<string, string>

const cookieStringSignal = signal('')
const cookiesSignal = signal<CookiesMap>({})

if (typeof document !== 'undefined') {
  const update = () => {
    if (document.cookie !== cookieStringSignal.value) {
      cookieStringSignal.value = document.cookie
      cookiesSignal.value = nookies.get()
    }
  }

  update()
  const intervalId = setInterval(update, 1000)
  window.addEventListener('beforeunload', () => {
    clearInterval(intervalId)
  })
}

export function createCookiesSignal() {
  return cookiesSignal
}
