import { roundToPrecision } from './roundToPrecision'
import { EXCHANGE_RATES } from '../../config/cart'
import type { PricesMap } from '../../types'

export const buildCostsMapFromGbp = (gbp: number, round = true): PricesMap => {
  const roundingFn = round
    ? Math.round
    : (value: number) => roundToPrecision(value, 2)

  return {
    GBP: gbp,
    USD: roundingFn(EXCHANGE_RATES.USD * gbp),
  }
}
