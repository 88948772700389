import cookies from 'nookies'
import { parse } from 'query-string'

const discountCookie = 'heights-discount'

export const useDiscountCode = (
  checkCookie = true
): string | null | undefined => {
  const cookieValues = typeof window !== 'undefined' ? cookies.get() : null
  const query =
    typeof window !== 'undefined' ? parse(window.location.search) : null
  const cookieValueDiscount = checkCookie
    ? cookieValues?.[discountCookie]
    : null
  const discountCode =
    typeof query?.discount_code === 'string'
      ? query.discount_code
      : cookieValueDiscount

  if (typeof window !== 'undefined' && discountCode) {
    cookies.set(null, discountCookie, discountCode, {
      maxAge: 3600 * 24,
    })
  }

  return discountCode
}
