/* eslint "no-async-promise-executor": "off" */
/* eslint "no-prototype-builtins": "off" */
/* eslint "@typescript-eslint/no-var-requires": "off" */
import hash from 'hash-sum'
import { useIntl } from '@heights/heights-context'
import { useCallback, useContext, useMemo, useRef } from 'react'
import { normalizeObjectFieldKey } from '@/utils/normalizeObjectFieldKey'
import { LocaleCodes } from '@/constants'
import { query as queryFn } from '@/utils/contentful'
import { dataQuery, transformLocale } from '@/utils'
import { CacheContext, PreviewContext, globalSsrCache } from '@/context'

const debugObject: unknown[] = []
if (typeof window !== 'undefined') {
  window.__useDataDebug = debugObject
}

export enum DataSourceType {
  CONTENTFUL = 'contentful',
  SHOPIFY = 'shopify',
  OKENDO = 'okendo',
}

export type DataSourcesObject = {
  [DataSourceType.SHOPIFY]:
    | typeof import('../../datasources/.shopify.json').default
    | null
  [DataSourceType.OKENDO]:
    | typeof import('../../datasources/.okendo.json').default
    | null
}

const localeToContentLangMap: Record<string, string> = {
  [LocaleCodes.EN_CA]: LocaleCodes.EN_US,
}

export function useData<D>(
  source: DataSourceType,
  query: string,
  variables: Record<string, unknown> = {},
  enabled = true
) {
  const { ssrCache } = useContext(CacheContext)
  const { preview } = useContext(PreviewContext)
  const { locale: lang } = useIntl()
  const contentLang = localeToContentLangMap[lang] ?? lang
  const dataId = `_` + hash({ source, query, variables, lang: contentLang })

  const dataIdRef = useRef(dataId)
  dataIdRef.current = dataId

  const fetcherPromise = useMemo(() => {
    if (!enabled) {
      return Promise.resolve(null)
    }

    const promise = new Promise<D>(async (resolve) => {
      debugObject.push({
        dataId: dataIdRef.current,
        query,
        variables,
        contentLang,
      })

      if (globalSsrCache.hasOwnProperty(dataIdRef.current)) {
        ssrCache[dataIdRef.current] = globalSsrCache[dataIdRef.current]
        return resolve(globalSsrCache[dataIdRef.current])
      }

      if (ssrCache.hasOwnProperty(dataIdRef.current)) {
        return resolve(ssrCache[dataIdRef.current])
      }

      // force null resolve if not available anyways
      if (typeof window !== 'undefined') {
        return resolve(ssrCache[dataIdRef.current] ?? null)
      }

      if (source === 'contentful') {
        const data = await queryFn(
          normalizeObjectFieldKey(transformLocale(contentLang)),
          query,
          variables,
          { preview }
        )
        ssrCache[dataIdRef.current] = data
        globalSsrCache[dataIdRef.current] = data
        return resolve(data)
      }

      const data = await dataQuery(source, query, variables)
      ssrCache[dataIdRef.current] = data
      globalSsrCache[dataIdRef.current] = data

      return resolve(data)
    })

    return promise
  }, [enabled, preview, source, query, variables, contentLang, ssrCache])

  const reader = useCallback((): D | null | undefined => {
    if (enabled) {
      if (globalSsrCache.hasOwnProperty(dataIdRef.current)) {
        return globalSsrCache[dataIdRef.current]
      }
      if (ssrCache.hasOwnProperty(dataIdRef.current)) {
        return ssrCache[dataIdRef.current]
      }
      // force null resolve if not available anyways
      if (typeof window !== 'undefined') {
        return ssrCache[dataIdRef.current] ?? null
      }
      throw fetcherPromise
    }

    return null
  }, [enabled, ssrCache, fetcherPromise])

  return reader
}
