export const Products = {
  THE_SMART_SUPPLEMENT: 2638620491861,
  THE_SMART_SUPPLEMENT_US_DEPRECATED: 1337,
  THE_SMART_SUPPLEMENT_US: 7180307660985,
  THE_SMART_SUPPLEMENT_PREORDER: 6192736436409,
  THE_SMART_SUPPLEMENT_OTP: 123456789,
  THE_SMART_SUPPLEMENT_OTP_2: 333666999,
  BRAINCARE_JOURNAL: 7018719051961,
  BRAINCARE_BUNDLE: 7113678487737,
} as const
