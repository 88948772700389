import { CountryCode } from '../graphql/storefront'
import { Cookies, LocaleCodes } from '@/constants'
import { useIntl } from '@heights/heights-context'
import { useCurrency } from '@heights/heights-react-hooks'
import { createCookiesSignal } from '../signals'
import { ReadonlySignal, computed } from '@preact/signals-react'

const CountryCodeValues = Object.values(CountryCode)
const cookiesSignal = createCookiesSignal()

export default function useCountrySignal(): ReadonlySignal<CountryCode> {
  const { locale } = useIntl()
  const { locale: currencyLocale } = useCurrency()

  return computed(() => {
    const value = cookiesSignal.value[Cookies.COUNTRY]
    // only use cookie value if in ROW and not US/CA
    const shouldUseCookieValue =
      locale.toLowerCase() === LocaleCodes.EN_GB &&
      value !== CountryCode.Us &&
      value !== CountryCode.Ca
    const rawCountryCode = (
      (shouldUseCookieValue ? value : null) ||
      currencyLocale.split('-').pop() ||
      locale.split('-').pop() ||
      LocaleCodes.EN_GB.split('-').pop()!
    ).toUpperCase()

    return (
      CountryCodeValues.find((code) => String(code) === rawCountryCode) ??
      CountryCode.Gb
    )
  })
}
