import { ProductsMapModel } from '@/types'
import { buildCostsMapFromGbp } from '@/utils/math'
import set from 'just-safe-set'
import { Products, ProductVariants } from '../constants'
import { VariantIdType } from '../types/VariantIdType'

type TOptions = {
  showOneTimePurchaseOptions?: boolean
  removeAnnualVariants?: boolean
  defaultSelectedVariantIdOverrides?: {
    productId: number
    variantId: VariantIdType
  }[]
  savingOverrides?: {
    productId: number
    variants: {
      variantId: VariantIdType
      saving: string
    }[]
  }[]
}

export const FALLBACK_PRODUCT_IMAGE = {
  src: '/cart-product.jpg',
  width: 240,
  height: 240,
}

export const productsInformationFactory = (
  options: TOptions = {}
): ProductsMapModel => {
  const productsInformation: ProductsMapModel = {
    [Products.THE_SMART_SUPPLEMENT]: {
      name: 'The Smart Supplement',
      href: '/products/the-smart-supplement',
      productId: Products.THE_SMART_SUPPLEMENT,
      productImage: FALLBACK_PRODUCT_IMAGE,
      defaultVariantId: ProductVariants[Products.THE_SMART_SUPPLEMENT].QUARTER,
      variants: [
        {
          productId: Products.THE_SMART_SUPPLEMENT,
          isSubscription: true,
          variantId: ProductVariants[Products.THE_SMART_SUPPLEMENT].MONTH,
          costPerIntervalFrequency: { ...buildCostsMapFromGbp(40), USD: 60 },
          costPerChargeFrequency: { ...buildCostsMapFromGbp(40), USD: 60 },
          baseCostPerChargeFrequency: { ...buildCostsMapFromGbp(40), USD: 60 },
          compareCostPerChargeFrequency: {},
          highlightLabel: 'Monthly plan',
          chargeIntervalFrequency: 29,
          orderIntervalFrequency: 29,
          chargeIntervalType: 'MONTH' as const,
          orderIntervalType: 'MONTH' as const,
          orderIntervalUnit: 'day' as const,
        },
        {
          productId: Products.THE_SMART_SUPPLEMENT,
          isSubscription: true,
          variantId: ProductVariants[Products.THE_SMART_SUPPLEMENT].QUARTER,
          costPerIntervalFrequency: buildCostsMapFromGbp(33),
          costPerChargeFrequency: buildCostsMapFromGbp(99),
          baseCostPerChargeFrequency: {
            ...buildCostsMapFromGbp(120),
            USD: 180,
          },
          compareCostPerChargeFrequency: {},
          saving: 'Save 18%',
          highlightLabel: '3 month plan',
          chargeIntervalFrequency: 87,
          orderIntervalFrequency: 29,
          chargeIntervalType: 'QUARTER' as const,
          orderIntervalType: 'MONTH' as const,
          orderIntervalUnit: 'day' as const,
        },
        {
          productId: Products.THE_SMART_SUPPLEMENT,
          isSubscription: true,
          variantId: ProductVariants[Products.THE_SMART_SUPPLEMENT].ANNUAL,
          costPerIntervalFrequency: buildCostsMapFromGbp(30),
          costPerChargeFrequency: buildCostsMapFromGbp(360),
          baseCostPerChargeFrequency: {
            ...buildCostsMapFromGbp(480),
            USD: 720,
          },
          compareCostPerChargeFrequency: {},
          highlightLabel: '12 month plan',
          saving: 'Save 25%',
          chargeIntervalFrequency: 348,
          orderIntervalFrequency: 29,
          chargeIntervalType: 'ANNUAL' as const,
          orderIntervalType: 'MONTH' as const,
          orderIntervalUnit: 'day' as const,
        },
      ],
    },
    [Products.THE_SMART_SUPPLEMENT_US_DEPRECATED]: {
      name: 'The Smart Supplement',
      href: '/products/the-smart-supplement',
      productId: Products.THE_SMART_SUPPLEMENT_US_DEPRECATED,
      productImage: FALLBACK_PRODUCT_IMAGE,
      defaultVariantId: ProductVariants[Products.THE_SMART_SUPPLEMENT_US].BASE,
      variants: [
        {
          productId: Products.THE_SMART_SUPPLEMENT_US_DEPRECATED,
          isSubscription: true,
          variantId: ProductVariants[Products.THE_SMART_SUPPLEMENT_US].BASE,
          planId: 'Z2lkOi8vc2hvcGlmeS9TZWxsaW5nUGxhbi83NDQyMjcwMDE=',
          costPerIntervalFrequency: { ...buildCostsMapFromGbp(40), USD: 55 },
          costPerChargeFrequency: { ...buildCostsMapFromGbp(40), USD: 55 },
          baseCostPerChargeFrequency: { ...buildCostsMapFromGbp(40), USD: 55 },
          compareCostPerChargeFrequency: {},
          highlightLabel: 'Monthly plan',
          chargeIntervalFrequency: 29,
          orderIntervalFrequency: 29,
          chargeIntervalType: 'MONTH' as const,
          orderIntervalType: 'MONTH' as const,
          orderIntervalUnit: 'day' as const,
        },
      ],
    },
    [Products.THE_SMART_SUPPLEMENT_US]: {
      name: 'The Smart Supplement',
      href: '/products/the-smart-supplement',
      productId: Products.THE_SMART_SUPPLEMENT_US,
      productImage: FALLBACK_PRODUCT_IMAGE,
      defaultVariantId: ProductVariants[Products.THE_SMART_SUPPLEMENT_US].BASE,
      variants: [
        {
          productId: Products.THE_SMART_SUPPLEMENT_US,
          isSubscription: true,
          variantId: ProductVariants[Products.THE_SMART_SUPPLEMENT_US].BASE,
          planId: 'Z2lkOi8vc2hvcGlmeS9TZWxsaW5nUGxhbi83NDQyMjcwMDE=',
          costPerIntervalFrequency: { ...buildCostsMapFromGbp(40), USD: 55 },
          costPerChargeFrequency: { ...buildCostsMapFromGbp(40), USD: 55 },
          baseCostPerChargeFrequency: { ...buildCostsMapFromGbp(40), USD: 55 },
          compareCostPerChargeFrequency: {},
          highlightLabel: 'Monthly plan',
          chargeIntervalFrequency: 29,
          orderIntervalFrequency: 29,
          chargeIntervalType: 'MONTH' as const,
          orderIntervalType: 'MONTH' as const,
          orderIntervalUnit: 'day' as const,
        },
      ],
    },
    [Products.THE_SMART_SUPPLEMENT_PREORDER]: {
      // Pre-order
      name: 'The Smart Supplement',
      href: '/products/the-smart-supplement',
      productId: Products.THE_SMART_SUPPLEMENT_PREORDER,
      productImage: FALLBACK_PRODUCT_IMAGE,
      defaultVariantId:
        ProductVariants[Products.THE_SMART_SUPPLEMENT_PREORDER].QUARTER,
      variants: [
        {
          productId: Products.THE_SMART_SUPPLEMENT_PREORDER,
          isSubscription: true,
          variantId:
            ProductVariants[Products.THE_SMART_SUPPLEMENT_PREORDER].MONTH,
          costPerIntervalFrequency: buildCostsMapFromGbp(40),
          costPerChargeFrequency: buildCostsMapFromGbp(40),
          baseCostPerChargeFrequency: buildCostsMapFromGbp(40),
          compareCostPerChargeFrequency: {},
          chargeIntervalFrequency: 60,
          orderIntervalFrequency: 60,
          chargeIntervalType: 'MONTH' as const,
          orderIntervalType: 'MONTH' as const,
          orderIntervalUnit: 'day' as const,
        },
        {
          productId: Products.THE_SMART_SUPPLEMENT_PREORDER,
          isSubscription: true,
          variantId:
            ProductVariants[Products.THE_SMART_SUPPLEMENT_PREORDER].QUARTER,
          costPerIntervalFrequency: buildCostsMapFromGbp(33),
          costPerChargeFrequency: buildCostsMapFromGbp(99),
          baseCostPerChargeFrequency: buildCostsMapFromGbp(120),
          compareCostPerChargeFrequency: {},
          saving: 'Save 18%',
          highlightLabel: 'Most popular',
          chargeIntervalFrequency: 87,
          orderIntervalFrequency: 60,
          chargeIntervalType: 'QUARTER' as const,
          orderIntervalType: 'MONTH' as const,
          orderIntervalUnit: 'day' as const,
        },
        {
          productId: Products.THE_SMART_SUPPLEMENT_PREORDER,
          isSubscription: true,
          variantId:
            ProductVariants[Products.THE_SMART_SUPPLEMENT_PREORDER].ANNUAL,
          costPerIntervalFrequency: buildCostsMapFromGbp(30),
          costPerChargeFrequency: buildCostsMapFromGbp(360),
          baseCostPerChargeFrequency: buildCostsMapFromGbp(480),
          compareCostPerChargeFrequency: {},
          saving: 'Save 25%',
          chargeIntervalFrequency: 348,
          orderIntervalFrequency: 60,
          chargeIntervalType: 'ANNUAL' as const,
          orderIntervalType: 'MONTH' as const,
          orderIntervalUnit: 'day' as const,
        },
      ],
    },
    [Products.THE_SMART_SUPPLEMENT_OTP]: {
      name: 'The Smart Supplement',
      href: '/products/the-smart-supplement',
      productId: Products.THE_SMART_SUPPLEMENT_OTP,
      productImage: FALLBACK_PRODUCT_IMAGE,
      defaultVariantId: ProductVariants[Products.THE_SMART_SUPPLEMENT_OTP].BASE,
      variants: [
        {
          productId: Products.THE_SMART_SUPPLEMENT_OTP,
          isSubscription: false,
          variantId: ProductVariants[Products.THE_SMART_SUPPLEMENT_OTP].BASE,
          costPerIntervalFrequency: buildCostsMapFromGbp(40),
          costPerChargeFrequency: buildCostsMapFromGbp(40),
          baseCostPerChargeFrequency: buildCostsMapFromGbp(40),
          compareCostPerChargeFrequency: {},
          highlightLabel: '30-day supply',
          saving: 'One-time purchase',
          chargeIntervalFrequency: null,
          chargeIntervalType: null,
          orderIntervalFrequency: null,
          orderIntervalType: null,
        },
      ],
    },
    [Products.THE_SMART_SUPPLEMENT_OTP_2]: {
      name: 'The Smart Supplement',
      href: '/products/the-smart-supplement',
      productId: Products.THE_SMART_SUPPLEMENT_OTP_2,
      productImage: FALLBACK_PRODUCT_IMAGE,
      defaultVariantId:
        ProductVariants[Products.THE_SMART_SUPPLEMENT_OTP_2].BASE,
      variants: [
        {
          productId: Products.THE_SMART_SUPPLEMENT_OTP_2,
          isSubscription: false,
          variantId: ProductVariants[Products.THE_SMART_SUPPLEMENT_OTP_2].BASE,
          costPerIntervalFrequency: buildCostsMapFromGbp(47),
          costPerChargeFrequency: buildCostsMapFromGbp(47),
          baseCostPerChargeFrequency: buildCostsMapFromGbp(47),
          compareCostPerChargeFrequency: {},
          highlightLabel: 'One-off bottle',
          subtitle: '1 month supply, delivered once',
          chargeIntervalFrequency: null,
          chargeIntervalType: null,
          orderIntervalFrequency: null,
          orderIntervalType: null,
        },
      ],
    },
    [Products.BRAINCARE_JOURNAL]: {
      name: 'The Braincare Journal',
      href: '/products/braincare-journal',
      productId: Products.BRAINCARE_JOURNAL,
      defaultVariantId: ProductVariants[Products.BRAINCARE_JOURNAL].BASE,
      productImage: FALLBACK_PRODUCT_IMAGE,
      variants: [
        {
          productId: Products.BRAINCARE_JOURNAL,
          isSubscription: false,
          variantId: ProductVariants[Products.BRAINCARE_JOURNAL].BASE,
          costPerIntervalFrequency: buildCostsMapFromGbp(24.99, false),
          costPerChargeFrequency: buildCostsMapFromGbp(24.99, false),
          baseCostPerChargeFrequency: buildCostsMapFromGbp(24.99, false),
          compareCostPerChargeFrequency: {},
          chargeIntervalFrequency: null,
          chargeIntervalType: null,
          orderIntervalFrequency: null,
          orderIntervalType: null,
        },
      ],
    },
    [Products.BRAINCARE_BUNDLE]: {
      name: 'The Braincare Bundle',
      href: '/products/braincare-bundle',
      productId: Products.BRAINCARE_BUNDLE,
      defaultVariantId: ProductVariants[Products.BRAINCARE_BUNDLE].BASE,
      productImage: FALLBACK_PRODUCT_IMAGE,
      variants: [
        {
          productId: Products.BRAINCARE_BUNDLE,
          isSubscription: false,
          variantId: ProductVariants[Products.BRAINCARE_BUNDLE].BASE,
          costPerIntervalFrequency: buildCostsMapFromGbp(59, false),
          costPerChargeFrequency: buildCostsMapFromGbp(59, false),
          baseCostPerChargeFrequency: buildCostsMapFromGbp(59, false),
          compareCostPerChargeFrequency: {},
          chargeIntervalFrequency: null,
          chargeIntervalType: null,
          orderIntervalFrequency: null,
          orderIntervalType: null,
        },
      ],
    },
  }

  if (options.showOneTimePurchaseOptions) {
    productsInformation[2638620491861].variants?.splice(0, 0, {
      productId: 2638620491861,
      isSubscription: false,
      variantId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTkyMDI3NjM0MDkyMQ==',
      costPerIntervalFrequency: buildCostsMapFromGbp(47),
      costPerChargeFrequency: buildCostsMapFromGbp(47),
      baseCostPerChargeFrequency: buildCostsMapFromGbp(47),
      compareCostPerChargeFrequency: {},
      highlightLabel: 'One-off bottle',
      subtitle: '1 month supply, delivered once',
      chargeIntervalFrequency: null,
      chargeIntervalType: null,
      orderIntervalFrequency: null,
      orderIntervalType: null,
    })
  }

  if (options.removeAnnualVariants) {
    productsInformation[2638620491861].variants =
      productsInformation[2638620491861].variants?.filter(
        (variant) =>
          variant.variantId !==
          ProductVariants[Products.THE_SMART_SUPPLEMENT].ANNUAL
      )
  }

  if (options.defaultSelectedVariantIdOverrides) {
    for (const override of options.defaultSelectedVariantIdOverrides) {
      set(
        productsInformation,
        `${override.productId}.selectedVariantId`,
        override.variantId
      )
    }
  }

  if (options.savingOverrides) {
    for (const override of options.savingOverrides) {
      productsInformation[override.productId].variants = productsInformation[
        override.productId
      ].variants?.map((variant) => {
        const relevantOverride = override.variants.find(
          (variantOverride) => variantOverride.variantId === variant.variantId
        )
        if (relevantOverride) {
          return { ...variant, saving: relevantOverride.saving }
        }
        return variant
      })
    }
  }

  return productsInformation
}
