import compact from 'just-compact'

const sessionStorageObject: Record<string, string> = {}

// @README
// https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/

export const sessionStorage = {
  keys: () => {
    try {
      return compact(
        new Array(window.sessionStorage.length)
          .fill(null)
          .map((_, index) => window.sessionStorage.key(index))
      )
    } catch (err) {
      console.warn(err)
    }
    return Object.keys(sessionStorageObject)
  },
  setItem: (id: string, value: string) => {
    sessionStorageObject[id] = value
    try {
      window.sessionStorage.setItem(id, value)
    } catch (err) {
      console.warn(err)
    }
  },
  getItem: (id: string) => {
    try {
      return window.sessionStorage.getItem(id)
    } catch (err) {
      console.warn(err)
    }
    return sessionStorageObject[id]
  },
}
