export function asResolverQuery<T>(
  field: string,
  query: string,
  arrayOptions: boolean | string = false
) {
  return [
    `${field}${
      arrayOptions
        ? `[${
            typeof arrayOptions === 'string'
              ? arrayOptions.match(/\*\[([^\]]+)\]/)?.[1] ?? arrayOptions
              : ''
          }]`
        : ''
    }->${query.replace(/\*\[[^{]+\]/, '').trim() || '{...}'}`,
    {},
  ] as [string, T]
}
