import { Products } from './Products'

export const ProductVariants = {
  [Products.THE_SMART_SUPPLEMENT]: {
    MONTH: 31639426564181,
    QUARTER: 32580687102037,
    ANNUAL: 31639426596949,
  },
  [Products.THE_SMART_SUPPLEMENT_US_DEPRECATED]: {
    BASE: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MTg2NzA0NzAxMDQ4OQ==',
  },
  [Products.THE_SMART_SUPPLEMENT_US]: {
    BASE: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MTg2NzA0NzAxMDQ4OQ==',
  },
  [Products.THE_SMART_SUPPLEMENT_PREORDER]: {
    MONTH: 38515892125881,
    QUARTER: 38515892224185,
    ANNUAL: 38515892289721,
  },
  [Products.THE_SMART_SUPPLEMENT_OTP]: {
    BASE: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTkyMDI3NjM0MDkyMQ==',
  },
  [Products.THE_SMART_SUPPLEMENT_OTP_2]: {
    BASE: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTkyMDI3NjM0MDkyMQ==',
  },
  [Products.BRAINCARE_JOURNAL]: {
    BASE: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MTIxMDk4MjY5NTA5Nw==',
  },
  [Products.BRAINCARE_BUNDLE]: {
    BASE: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MTYwNTM4NjMzODQ4OQ==',
  },
} as const
