import compact from 'just-compact'

const localStorageObject: Record<string, string> = {}

// @README
// https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/

export const localStorage = {
  setItem: (id: string, value: string) => {
    localStorageObject[id] = value
    try {
      window.localStorage.setItem(id, value)
    } catch (err) {
      console.warn(err)
    }
  },
  getItem: (id: string) => {
    try {
      return window.localStorage.getItem(id)
    } catch (err) {
      console.warn(err)
    }
    return localStorageObject[id]
  },
}
