import DataLoader from 'dataloader'

type QueryOptions = {
  preview?: boolean
}

type BatchOperation = {
  locale: string
  query: string
  params: Record<string, unknown>
}

const dataLoader = new DataLoader(
  async (keys: readonly BatchOperation[]) => {
    let rawBody = ''
    try {
      const url = new URL('/api/query', process.env.GROQ_DATA_URL)
      const response = await fetch(url, {
        method: 'POST',
        headers: { ['Content-Type']: 'application/json' },
        body: JSON.stringify({
          batch: true,
          operations: keys,
        }),
      })
      rawBody = await response.text()
      return JSON.parse(rawBody)
    } catch (err) {
      console.error(`dataloader failed`, keys, rawBody)
      throw err
    }
  },
  {
    batchScheduleFn: (callback) => setTimeout(callback, 0),
  }
)

const previewDataLoader = new DataLoader(
  async (keys: readonly BatchOperation[]) => {
    let rawBody = ''
    try {
      const url = new URL('/api/preview', process.env.GROQ_DATA_URL)
      const response = await fetch(url, {
        method: 'POST',
        headers: { ['Content-Type']: 'application/json' },
        body: JSON.stringify({
          batch: true,
          operations: keys,
        }),
      })
      rawBody = await response.text()
      return JSON.parse(rawBody)
    } catch (err) {
      console.error(`Preview dataloader failed`, keys, rawBody)
      throw err
    }
  },
  {
    batchScheduleFn: (callback) => setTimeout(callback, 0),
  }
)

export async function query<T = any>(
  locale: string,
  query: string,
  params: Record<string, unknown> = {},
  options?: QueryOptions
) {
  const operation: BatchOperation = {
    locale,
    query,
    params,
  }
  return options?.preview
    ? (previewDataLoader.load(operation) as Promise<T>)
    : (dataLoader.load(operation) as Promise<T>)
}
