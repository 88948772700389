export const PersistedQueryKeys = [
  'utm_source',
  'utm_campaign',
  'utm_content',
  'utm_data_source',
  'utm_medium',
  'utm_term',
  'utm_timestamp',
  'tw_source',
  'tw_adid',
  'duel_a',
  'fbclid',
] as const
