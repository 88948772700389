import React, { useContext, useMemo } from 'react'
import compact from 'just-compact'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { PreviewContext } from '@/context'
import { LinkComponentProps } from '@heights/heights-ui-library'

export const LinkComponent = ({
  to,
  children,
  partiallyActive,
  activeClassName,
  className,
  ...props
}: LinkComponentProps) => {
  const router = useRouter()
  const isActive = useMemo(() => router.pathname === to, [to, router.pathname])
  const isPartiallyActive = useMemo(
    () => !isActive && router?.pathname?.startsWith(to),
    [to, isActive, router.pathname]
  )

  return (
    <Link
      href={to}
      locale={false}
      className={compact([
        className,
        isActive && activeClassName,
        isPartiallyActive && partiallyActive,
      ]).join(' ')}
      {...props}
    >
      {children}
    </Link>
  )
}
