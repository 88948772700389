import { CtaSize, CtaStyle, Theme } from '@/constants'
import { commonCtaActions } from '@/contentful/utils/commonValues'
import { defineDocument, defineObject } from 'sanity-typed-queries'

// @README these stubs are used to reduce recursive types

type LandingPageTypeStub = {
  _type: 'landingPage'
  _id: string
  _rev: string
  fields: {
    title: string
    urlHandle: string
  }
}

type ArticleTypeStub = {
  _type: 'article'
  _id: string
  _rev: string
  fields: {
    title: string
    urlHandle: string
  }
}

type ProductVariantStub = {
  _type: 'productVariant'
  _id: string
  _rev: string
  fields: {
    shopifyVariantId?: string
    shopifyPlanId?: string
  }
}

const ctaActionFields = defineObject(
  'ctaActionFields',
  {
    reference: { type: 'string' },
    trackingId: { type: 'string' },
    type: { type: 'string', options: { list: commonCtaActions } },
    theme: {
      type: 'string',
      options: { list: Object.values(Theme) },
    },
    size: {
      type: 'string',
      options: { list: Object.values(CtaSize) },
    },
    style: {
      type: 'string',
      options: { list: Object.values(CtaStyle) },
    },
    title: { type: 'string' },
    url: { type: 'url' },
    contentId: { type: 'string' },
    linkContent: {
      type: 'reference',
      to: [
        { type: 'landingPage' },
        { type: 'article' },
        { type: 'productVariant' },
      ],
    },
  },
  [{} as LandingPageTypeStub, {} as ArticleTypeStub, {} as ProductVariantStub]
)

export const ctaAction = defineDocument(
  'ctaAction',
  {
    fields: {
      type: 'ctaActionFields',
    },
  },
  [ctaActionFields.ctaActionFields]
)
