import flat from 'flat'
import get from 'just-safe-get'
import { theme } from '@heights/heights-theme'
import { Theme, NavTheme, CtaSize, CtaStyle } from '../../constants'

export const commonSections = [
  'articleCtaStrip',
  'articleVideo',
  'bannerSection',
  'contentHighlightSection',
  'expertsSection',
  'featureSection',
  'imageWithCaption',
  'gridSection',
  'hero',
  'heroSection',
  'ingredientsSection',
  'pdpHero',
  'quoteSection',
  'section',
  'subscriptionSection',
  'testimonialSection',
  'timelineSection',
  'trustpilotSection',
  'experimentContainer',
  'heroSection',
  'tolstoyWidget',
].sort((a, b) => a.localeCompare(b))

export const supportedExperimentContent = [
  ...commonSections.filter((section) => section !== 'experimentContainer'),
].sort((a, b) => a.localeCompare(b))

export const commonCtaActions = [
  'link',
  'modal',
  'typeform',
  'video',
  'product-variant',
  'bundle',
].sort((a, b) => a.localeCompare(b))
export const commonCtaLinkedModels = [
  'article',
  'landingPage',
  'productVariant',
].sort((a, b) => a.localeCompare(b))

export const supportedSections = [
  'article',
  'mediaPlaybackCta',
  'subscriptionSection',
  'textItem',
  'blockCardItem',
  'ctaAction',
  'section',
  'product',
  'productVariant',
].sort((a, b) => a.localeCompare(b))

export const supportedPdpHeroComponents = ['subscriptionSection']

export const supportedSectionTypes = (
  [
    // SUPORTED SECTION TYPES
    'embedded-benefit',
    'embedded-accordion',
    'benefit-section',
    'contact-us',
    'okendo-reviews-carousel',
    'buybox-section',
    'ingredients-usp',
    'faq-section',
    'okendo-reviews',
    'media-playback',
    ['reviews-section', ['v2']],
    'bhs-section',
    'ugc-section',
    'bhs-score-section',
    'related-articles',
    'content-cards-section',
    'rich-text',
    'seen-in-strip',
    'subscribe-banner',
    'ingredients-table',
    'marquee-banner',
    'video-testimonial',
    'product-block',
    'product-block-inline',
    'sticky-snackbar',
    'table-of-contents',
    'basic-section',
    'ingredients-grid',
    'quote-carousel',
    'centered-basic-section',
    'subscribe-form-large',
    'cover-image',
    'customer-testimonial-carousel',
    'experts-section',
    'community-stories-carousel',
    'ugc-masonry-section',
    'inline-video-section',
    'blood-trial-section/basic',
    'research-studies-table-section',
    'scientific-evidence-section',
    'simple-cta-banner',
    'content-highlight',
    'billboard-image',
    'usp-grid-section',
    'tab-section',
    'image-highlight-section',
    'image-navigation-cards-section',
    'image-navigation-card',
    'product-gallery',
    'product-gallery-card',
    'newsletter-subscribe-banner',
    'collapsible-table-section',
    'comparison-section',
  ] as (string | [string, string[]])[]
)
  .reduce<string[]>((acc, type) => {
    if (typeof type === 'string') {
      acc.push(type)
    } else {
      type[1].forEach((version) => {
        acc.push(`${type[0]}/${version}`)
      })
    }
    return acc
  }, [])
  .sort((a, b) => a.localeCompare(b))

export enum SectionFeatureFlags {
  SUBSCRIBE_FORM__EMAIL_ONLY = 'subscribe-form/email-only',
  SUBSCRIBE_FORM__WITH_PHONE = 'subscribe-form/with-phone',
  BASIC_SECTION__BLOCK = 'basic-section/block',
  PRODUCT_BLOCK__REVERSE_ORDER = 'product-block/reverse-order',
  CONTENT_HIGHLIGHT__BUTTON_CTA = 'content-highlight/cta-button',
  CONTENT_HIGHLIGHT__REVERSE_CONTENT = 'content-highlight/reverse-content',
  CONTENT_HIGHLIGHT__MOBILE_ABOVE_CONTENT = 'content-highlight/mobile-above-content',
}
export const supportedSectionFeatureFlags = Object.values(
  SectionFeatureFlags
).sort((a, b) => a.localeCompare(b))

export const supportedArticleSections = [
  ...commonSections,
  'articleVideo',
  'articleCtaStrip',
  'imageWithCaption',
].sort((a, b) => a.localeCompare(b))

export const supportedArticleHyperlinks = ['article', 'imageWithCaption'].sort(
  (a, b) => a.localeCompare(b)
)

export const landingPageHeroEntries = ['hero', 'pdpHero'].sort((a, b) =>
  a.localeCompare(b)
)

export const icons = [
  'spinner',
  'podcast',
  'minus',
  'plus',
  'burger',
  'close',
  'videoPlay',
  'monthlySubscription',
  'lock',
  'arrowDown',
  'clap',
  'audioPlay',
  'audioPause',
  'soundSleep',
  'slowsCognitiveDecline',
  'reducesStress',
  'nervousSystem',
  'nervoousSystem',
  'moreEnergy',
  'learning',
  'improvesMood',
  'focus',
  'fightsFatigue',
  'betterRecall',
  'cardioHealth',
  'benefitsBetterRecall',
  'more',
  'time',
  'servings',
  'calories',
  'share',
  'linkedin',
  'youtube',
  'instagram',
  'twitter',
  'facebook',
  'paymentVisa',
  'paymentPaypal',
  'paymentMastercard',
  'paymentGooglePay',
  'paymentDiscover',
  'paymentDinersClub',
  'paymentApplePay',
  'paymentAmex',
  'subsCancel',
  'subsDelivery',
  'subsLetterbox',
  'research',
  'ingredients',
  'hippocampus',
  'faq',
  'account',
  'experts',
  'carouselArrow',
  'lhAll',
  'lhBrainHealth',
  'lhBrainScience',
  'lhClubhouse',
  'lhIngredients',
  'lhMentalWellbeing',
  'lhNutrition',
  'lhPodcast',
  'check',
  'journal',
  'capsule',
  'community',
  'glutenFree',
  'noAllergens',
  'noContaminants',
  'noFillers',
  'nonGmo',
  'plantBased',
  'gutHealth',
  'immunity',
].sort((a, b) => a.localeCompare(b))

export const alignmentOptions = [
  'left' as const,
  'center' as const,
  'right' as const,
]

export const foregroundColors = [
  'primary' as const,
  'light' as const,
  'dark' as const,
]

export const backgroundColors = [
  'background' as const,
  'blue' as const,
  'blue.muted' as const,
  'green' as const,
  'grey' as const,
  'peach' as const,
  'pink' as const,
  'primary' as const,
  'white' as const,
  'brightblue' as const,
  'stalegrey' as const,
  'lightblue' as const,
].sort((a, b) => a.localeCompare(b))

export const pdpHeroVariants = ['current', 'next'].sort((a, b) =>
  a.localeCompare(b)
)

export const pdpHeroCarouselVariants = ['default', 'reduced']

export const supportedModalComponents = ['section', 'subscriptionSection']
export const supportedModalLinkedContent = ['nutrientList']

export const themeValues = Object.values(Theme)

export const navThemeValues = Object.values(NavTheme)

export const ctaSizeValues = Object.values(CtaSize)

export const ctaStyleValues = Object.values(CtaStyle)

export const themeColorValues = Object.keys(flat(get(theme, 'colors'))).filter(
  (colorKey) =>
    [
      'blue',
      'brightBlue',
      'mint',
      'green',
      'neutral',
      'red',
      'yellow',
      'peach',
      'teal',
      'purple',
    ].includes(colorKey.split('.')[0])
)

export enum MediaOverlay {
  NONE = 'none',
  LIGHT = 'light',
  MEDIUM = 'medium',
  DARK = 'dark',
}

export enum HeroStyle {
  SHORT_LEFT_ALIGNED = 'short-left-aligned',
  SHORT_CENTER_ALIGNED = 'short-center-aligned',
  SHORT_LEFT_ALIGNED_WITH_IMAGE = 'short-left-aligned-with-image',
  BIG_WITH_CENTERED_IMAGE = 'big-with-centered-image',
  BIG_WITH_BOTTOM_IMAGE = 'big-with-bottom-image',
  FULLSCREEN_LEFT_ALIGNED = 'fullscreen-left-aligned',
  FULLSCREEN_CENTER_ALIGNED = 'fullscreen-center-aligned',
  STACKED_CENTER_ALIGNED = 'stacked-center-aligned',
  FULL_BLEED = 'full-bleed',
  FULL_BLEED_LIGHT = 'full-bleed-light',
}
