import {
  backgroundColors,
  foregroundColors,
} from '@/contentful/utils/commonValues'
import { defineDocument, defineObject } from 'sanity-typed-queries'

const themeFields = defineObject('themeFields', {
  reference: { type: 'string' },
  foregroundColour: {
    type: 'string',
    options: { list: foregroundColors },
  },
  backgroundColour: {
    type: 'string',
    options: { list: backgroundColors },
  },
})

export const theme = defineDocument(
  'theme',
  {
    fields: { type: 'themeFields' },
  },
  [themeFields.themeFields]
)
