import { defineDocument, defineObject } from 'sanity-typed-queries'
import { product } from './product'
import { asset } from './asset'
import { ctaAction } from './ctaAction'
import { JsonType } from './JsonType'
import { textItem } from './textItem'
import { supplementFacts } from './supplementFacts'
import { backgroundColors } from '@/contentful/utils/commonValues'
import { subscriptionSection } from './subscriptionSection'

const pdpHeroFields = defineObject(
  'pdpHeroFields',
  {
    reference: { type: 'string' },
    trackingId: { type: 'string' },
    heroVariant: {
      type: 'string',
      options: { list: ['current' as const, 'next' as const] },
    },
    name: { type: 'string' },
    showTrustpilot: { type: 'boolean' },
    hideTrustpilotLink: { type: 'boolean' },
    productId: { type: 'number' },
    product: { type: 'reference', to: [{ type: 'product' }] },
    description: { type: 'json' },
    productBenefits: { type: 'array', of: [{ type: 'string' }] },
    media: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'asset' }] }],
    },
    ctaAction: {
      type: 'reference',
      to: [{ type: 'ctaAction' }],
    },
    moreActions: {
      type: 'array',
      of: [
        {
          type: 'reference',
          to: [{ type: 'ctaAction' }],
        },
      ],
    },
    bannerTitle: { type: 'string' },
    bannerBackgroundColor: {
      type: 'string',
      options: { list: backgroundColors },
    },
    servingInfos: { type: 'array', of: [{ type: 'string' }] },
    components: {
      type: 'array',
      of: [
        {
          type: 'reference',
          to: [{ type: 'subscriptionSection' }],
        },
      ],
    },
  },
  [
    {} as JsonType,
    asset.asset,
    ctaAction.ctaAction,
    product.product,
    textItem.textItem,
    supplementFacts.nutrientList,
    subscriptionSection.subscriptionSection,
  ]
)

export const pdpHero = defineDocument(
  'pdpHero',
  {
    fields: { type: 'pdpHeroFields' },
  },
  [pdpHeroFields.pdpHeroFields]
)
