import { useMemo } from 'react'
import { Cookies, Locales } from '@/constants'
import cookies from 'nookies'
import { useMounted } from './useMounted'

export enum AutoLocaleSource {
  PATHNAME = 'pathname',
  STORAGE = 'storage',
  NEXT = 'next',
}

export function useAutoLocale(localeId?: string) {
  const isMounted = useMounted()
  const detectedLocale = useMemo(() => {
    const { [Cookies.LANG_CULTURE]: countryCode, locale: savedLocaleString } =
      cookies.get()
    // @README don't check cookies unless mounted
    // this is to prevent hydration errors
    if (typeof window !== 'undefined' && isMounted) {
      const localeFromStorage =
        !!savedLocaleString &&
        Locales.find(
          ({ id }) => id.toLowerCase() === savedLocaleString.toLowerCase()
        )
      if (localeFromStorage) {
        return {
          source: AutoLocaleSource.STORAGE,
          locale: localeFromStorage,
        }
      }

      const localeFromNext =
        !!countryCode &&
        Locales.find(({ id }) => id.toLowerCase() === countryCode.toLowerCase())
      if (localeFromNext) {
        return {
          source: AutoLocaleSource.NEXT,
          locale: localeFromNext,
          country: countryCode,
        }
      }
    }
    return null
  }, [localeId, isMounted])

  return detectedLocale
}
