import qs from 'query-string'
import { sessionStorage } from '@/utils/sessionStorage'
import { computed, signal } from '@preact/signals-react'

const SESSIONSTORAGE_KEY_PREFIX = 'usePersistedQueryParams|'

type ParamsType<K extends string = string> = Partial<
  Record<K, string | (string | null)[] | null>
>
const allParamsSignal = signal<ParamsType>({})

if (typeof window !== 'undefined') {
  const nextParams: ParamsType = {}
  const sessionKeys = sessionStorage
    .keys()
    .filter((key) => key.startsWith(SESSIONSTORAGE_KEY_PREFIX))
    .map((key) => key.replace(SESSIONSTORAGE_KEY_PREFIX, ''))
  const parsedQuery = qs.parse(window.location.search)
  const allKeys = Array.from(
    new Set([...sessionKeys, ...Object.keys(parsedQuery)])
  )
  allKeys.forEach((key) => {
    if (key in parsedQuery) {
      nextParams[key] = parsedQuery[key]
      sessionStorage.setItem(
        `${SESSIONSTORAGE_KEY_PREFIX}${key}`,
        JSON.stringify(parsedQuery[key])
      )
    } else if (allKeys.includes(key)) {
      const rawValue = sessionStorage.getItem(
        `${SESSIONSTORAGE_KEY_PREFIX}${key}`
      )
      nextParams[key] = rawValue ? JSON.parse(rawValue) : null
    }
  })
  allParamsSignal.value = nextParams
}

export function createPersistedQueryParamsSignal<K extends string>(
  ...keys: K[]
) {
  if (keys.length === 0) {
    throw new Error('No keys supplied')
  }

  return computed(() => {
    const entries = Object.entries(allParamsSignal.value).filter(([key]) =>
      keys.some((k) => k === key)
    )
    return Object.fromEntries(entries) as ParamsType<K>
  })
}
