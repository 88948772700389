import { defineDocument, defineObject } from 'sanity-typed-queries'

// @README using type stubs here because otherwise typescript throws an "excessively deep" error

type AssetImageDetailsStub = {
  _type: 'assetImageDetails'
  width?: number
  height?: number
}

type AssetDetailsStub = {
  _type: 'assetDetails'
  size?: number
  image?: AssetImageDetailsStub
}

type AssetFileStub = {
  _type: 'assetFile'
  url?: string
  contentType?: string
  details?: AssetDetailsStub
}

const assetFields = defineObject(
  'assetFields',
  {
    title: { type: 'string' },
    description: { type: 'text' },
    file: { type: 'assetFile' },
  },
  [{} as AssetFileStub]
)

export const asset = defineDocument(
  'asset',
  {
    fields: {
      type: 'assetFields',
    },
  },
  [assetFields.assetFields]
)
