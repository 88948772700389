import { DataSourceType, useData } from './useData'
import { globalData, productVariant, textItem } from '@/schemas'
import { asResolverQuery } from '@/utils/groq'

const [variantQuery, variantType] = productVariant.builder
  .map((variant) => ({
    images: variant.fields?.images?.resolveIn('fields').use(),
  }))
  .use()

const [uspQuery, uspType] = textItem.builder
  .map((item) => ({
    icon: item.fields.icon?.use(),
    title: item.fields.title?.use(),
  }))
  .pick(['icon', 'title'])
  .use()

const [query, type] = globalData.builder
  .subquery({
    cartProductSuggestions: asResolverQuery<typeof variantType>(
      'fields.cartProductSuggestions',
      variantQuery,
      true
    ),
    cartUsps: asResolverQuery<typeof uspType>(
      'fields.cartUsps',
      uspQuery,
      true
    ),
  })
  .first()
  .use()

export function useGlobalData() {
  const reader = useData<typeof type>(DataSourceType.CONTENTFUL, query)
  return reader()
}
