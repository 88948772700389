export const PAYMENT_TYPES = [
  'paymentApplePay',
  'paymentGooglePay',
  'paymentAmex',
  'paymentDinersClub',
  'paymentDiscover',
  'paymentMastercard',
  'paymentPaypal',
  'paymentVisa',
]

export const SUPPORTED_CURRENCIES = ['GBP', 'USD'] as const

export const EXCHANGE_RATES = {
  GBP: 1,
  USD: 1.25,
}
