export async function dataQuery<T = any>(
  source: 'shopify' | 'okendo',
  query: string,
  params: Record<string, unknown> = {}
) {
  try {
    const url = new URL(`/api/${source}`, process.env.GROQ_DATA_URL)
    url.searchParams.append('query', query)
    url.searchParams.append('params', JSON.stringify(params))
    const response = await fetch(url)
    return (await response.json()) as T
  } catch (err) {
    console.error(`dataQuery failed`, query, params)
    throw err
  }
}
