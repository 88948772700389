import { defineDocument, defineObject } from 'sanity-typed-queries'
import { productVariant } from './productVariant'

const productDynamicBannerEntryFields = defineObject(
  'productDynamicBannerEntryFields',
  {
    content: { type: 'string' },
    hideIfAnyPresent: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'productVariant' }] }],
    },
    hideIfAllPresent: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'productVariant' }] }],
    },
    showIfAnyPresent: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'productVariant' }] }],
    },
    showIfAllPresent: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'productVariant' }] }],
    },
  },
  [productVariant.productVariant]
)

export const productDynamicBannerEntry = defineDocument(
  'productDynamicBannerEntry',
  {
    fields: { type: 'productDynamicBannerEntryFields' },
  },
  [productDynamicBannerEntryFields.productDynamicBannerEntryFields]
)
