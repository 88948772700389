import compact from 'just-compact'
import dayjs from 'dayjs'
import useCountrySignal from './useCountrySignal'
import equal from 'fast-deep-equal'
import {
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useProducts } from './useProducts'
import {
  decodeShopifyId,
  normalizeShopifyId,
  shopifyIdsMatch,
} from '@/utils/shopify'
import type { VariantIdType } from '@/types/VariantIdType'
import { useDiscountCode } from './useDiscountCode'
import {
  CartBuyerIdentity,
  CartFieldsFragmentFragment,
} from '@/graphql/storefront'
import { localStorage } from '@/utils/localStorage'
import { CartEvent, CartStatus, CartStatusChangeEvent } from 'async-cart'
import type { StorefrontCart } from '@/storefront/StorefrontCart'
import { createFbParamsSignal } from '@/signals'
import { computed, effect, signal } from '@preact/signals-react'

type LineItem = {
  quantity: number
  productId: number
  variantId: VariantIdType
  attributes?: Record<string, string>
}

type ShopifyLineItem = {
  id?: string
  merchandiseId: string
  planId?: string
  quantity: number
  attributes?: {
    key: string
    value: string
  }[]
}

const MAX_CART_AMOUNT = 10

const StorefrontCartPromise =
  typeof window !== 'undefined' ? import('@/storefront/StorefrontCart') : null

const fbParamsSignal = createFbParamsSignal()

export const customStorefrontAttributesSignal = signal<Record<string, unknown>>(
  {}
)

export function useStorefrontCart() {
  const products = useProducts()
  const countryCode = useCountrySignal()
  const deepLinkDiscountCode = useDiscountCode()
  const initializedSignal = signal(false)
  const storefrontCartRef = useRef<StorefrontCart | null>(null)
  const [cart, setCart] = useState<CartFieldsFragmentFragment | null>(null)
  const [updating, setUpdating] = useState(false)

  // helper methods
  const cartAttributesSignal = computed<Record<string, string>>(() => ({
    ...customStorefrontAttributesSignal.value,
    ...(fbParamsSignal.value.fbc ? { _fbc: fbParamsSignal.value.fbc } : {}),
    ...(fbParamsSignal.value.fbp ? { _fbp: fbParamsSignal.value.fbp } : {}),
  }))

  const convertToShopifyLineItems = useCallback(
    (items: LineItem[]): ShopifyLineItem[] => {
      return compact(
        items.map(({ productId, variantId, quantity, attributes = {} }) => {
          const product = products?.[productId].variants?.find((variant) =>
            shopifyIdsMatch(variant.variantId, variantId)
          )
          if (!product) return null
          return {
            merchandiseId: normalizeShopifyId(
              decodeShopifyId(product.variantId)
            ),
            ...(product.isSubscription && product.planId
              ? { sellingPlanId: product.planId }
              : {}),
            quantity: Math.min(MAX_CART_AMOUNT, quantity ?? 1),
            attributes: Object.entries(attributes).map(([key, value]) => ({
              key,
              value,
            })),
          }
        })
      )
    },
    [products]
  )

  const addProducts = useCallback(
    async (items: LineItem[]) => {
      const allProducts = Object.values(products)
      const itemsToUpdate: ShopifyLineItem[] = []
      const shopifyLineItems = convertToShopifyLineItems(items).map(
        (lineItem) => {
          const product = allProducts.find((product) =>
            product.variants.some((variant) =>
              shopifyIdsMatch(variant.variantId, lineItem.merchandiseId)
            )
          )

          const cartLineItem = cart?.lines.edges.find(
            (cartItem) =>
              cartItem.node.merchandise.id === lineItem.merchandiseId
          )
          const currentCartAmount = cartLineItem?.node?.quantity || 0
          const addQuantity = Math.min(
            MAX_CART_AMOUNT - currentCartAmount,
            lineItem.quantity - currentCartAmount
          )

          if (addQuantity > 0 && product?.bundleVariants?.length) {
            const lineItemsToUpdate = compact(
              product.bundleVariants.map<ShopifyLineItem | null>(
                ({ variantId, planId }) => {
                  const merchandiseId = normalizeShopifyId(
                    decodeShopifyId(variantId)
                  )
                  const existingItem =
                    itemsToUpdate.find((item) => item.merchandiseId) ??
                    cart?.lines.edges.find(
                      (cartItem) =>
                        cartItem.node.merchandise.id === merchandiseId
                    )?.node
                  if (!existingItem) return null
                  const newQuantity = Math.max(
                    0,
                    existingItem.quantity - addQuantity
                  )
                  return {
                    id: existingItem.id,
                    merchandiseId,
                    sellingPlanId: planId ?? undefined,
                    quantity: newQuantity,
                  }
                }
              )
            )
            itemsToUpdate.push(...lineItemsToUpdate)
          }

          return {
            ...lineItem,
            quantity: addQuantity,
          }
        }
      )

      if (itemsToUpdate.length) {
        await storefrontCartRef.current?.addLineItems(shopifyLineItems)
        return storefrontCartRef.current?.updateLineItems(itemsToUpdate)
      }

      return storefrontCartRef.current?.addLineItems(shopifyLineItems)
    },
    [cart, products, convertToShopifyLineItems, storefrontCartRef]
  )

  const updateProducts = useCallback(
    async (items: LineItem[]) => {
      const shopifyLineItems = compact(
        convertToShopifyLineItems(items).map((lineItem) => {
          const cartLineItem = cart?.lines.edges.find(
            (cartItem) =>
              cartItem.node.merchandise.id === lineItem.merchandiseId
          )
          if (cartLineItem) {
            return {
              ...lineItem,
              id: cartLineItem.node.id,
              quantity: Math.min(MAX_CART_AMOUNT, lineItem.quantity),
            }
          }
          return null
        })
      )
      return storefrontCartRef.current?.updateLineItems(shopifyLineItems)
    },
    [cart?.lines.edges, convertToShopifyLineItems, storefrontCartRef]
  )

  const removeProducts = useCallback(
    async (lineIds: string[]) => {
      return storefrontCartRef.current?.removeLineItems(lineIds)
    },
    [storefrontCartRef]
  )

  const updateDiscountCodes = useCallback(
    async (discountCodes: string[]) => {
      // @README passing an empty discount code when clearing is required
      // for storefront to recalc the line items
      return storefrontCartRef.current?.updateDiscountCodes(
        discountCodes.length === 0 ? [''] : discountCodes
      )
    },
    [storefrontCartRef]
  )

  // this should only run once on load
  useEffect(() => {
    StorefrontCartPromise?.then(({ StorefrontCart }) => {
      startTransition(() => {
        const cartObject = new StorefrontCart()
        storefrontCartRef.current = cartObject

        // create or fetch cart and run initial load updates
        const localCartId = localStorage.getItem('cartId')
        const localCartCreatedAt = localStorage.getItem('cartCreatedAt')
        const cartId =
          localCartId &&
          localCartCreatedAt &&
          dayjs().isBefore(dayjs(localCartCreatedAt).add(7, 'days'), 'day') // The cart expires after 10 days | we'll remove it after 7 manually
            ? localCartId // cart has not expired yet - use it
            : null // otherwise don't use it

        const buyer = {
          countryCode: countryCode.value as CartBuyerIdentity['countryCode'],
        }

        const deeplinkDiscountCodes = compact([deepLinkDiscountCode])

        const createCart = async () => {
          const createdCart = await cartObject.create({
            buyer,
            attributes: cartAttributesSignal.value,
            discountCodes: deeplinkDiscountCodes,
          })
          if (createdCart) {
            localStorage.setItem('cartId', createdCart.id)
            localStorage.setItem('cartCreatedAt', createdCart.createdAt)
          }
        }

        if (typeof cartId === 'string') {
          const fetchPromise = cartObject.fetch(cartId).catch(() => {
            // if the fetch fails we should
            createCart().then(() => {
              initializedSignal.value = true
            })
          })
          const updateOrCreatePromise = fetchPromise.then(async (cart) => {
            return cart
              ? Promise.all([
                  cartObject.updateBuyer(buyer),
                  deepLinkDiscountCode
                    ? cartObject.updateDiscountCodes(deeplinkDiscountCodes)
                    : Promise.resolve(),
                ])
              : createCart()
          })
          updateOrCreatePromise.then(() => {
            initializedSignal.value = true
          })
        } else {
          createCart().then(() => {
            initializedSignal.value = true
          })
        }
      })
    })
  }, [])

  // this should run once after initialized
  // initialized means once cart has been fetched once or created
  effect(() => {
    const cartObject = storefrontCartRef.current
    if (initializedSignal.value && cartObject) {
      const cartChangeHandler = (event: Event) => {
        if (event instanceof CartEvent) {
          if (event.detail.cart) {
            setCart(event.detail.cart)
          }
        }
      }

      const idleHandler = () => {
        setUpdating(false)
      }

      const statusHandler = (event: Event) => {
        if (
          event instanceof CartStatusChangeEvent &&
          event.detail.status === CartStatus.UPDATING
        ) {
          setUpdating(true)
        }
      }

      setCart(cartObject.cart)
      cartObject.addEventListener('change', cartChangeHandler)
      cartObject.addEventListener('statuschange', statusHandler)
      cartObject.addEventListener('idle', idleHandler)

      return () => {
        cartObject.removeEventListener('change', cartChangeHandler)
        cartObject.removeEventListener('statuschange', statusHandler)
        cartObject.removeEventListener('idle', idleHandler)
      }
    }
  })

  effect(() => {
    const cartObject = storefrontCartRef.current
    const attributes = cartObject?.cart?.attributes ?? []
    const attributesObject = Object.fromEntries(
      attributes.map((attr) => [attr.key, attr.value])
    )
    if (
      initializedSignal.value &&
      cartObject &&
      !equal(attributesObject, cartAttributesSignal.value)
    ) {
      cartObject.updateAttributes(cartAttributesSignal.value)
    }
  })

  return useMemo(
    () => ({
      updating,
      cart,
      addProducts,
      updateProducts,
      removeProducts,
      updateDiscountCodes,
    }),
    [
      cart,
      updating,
      addProducts,
      updateProducts,
      removeProducts,
      updateDiscountCodes,
    ]
  )
}
