import { defineDocument, defineObject } from 'sanity-typed-queries'
import { asset } from './asset'

type ProductVariantStub = {
  _type: 'productVariant'
  _id: string
  _rev: string
  fields: {
    shopifyVariantId?: string
  }
}

const productVariantFields = defineObject(
  'productVariantFields',
  {
    reference: { type: 'string' },
    name: { type: 'string' },
    subtitle: { type: 'string' },
    description: { type: 'string' },
    shopifyVariantId: { type: 'string' },
    shopifyPlanId: { type: 'string' },
    defaultVariant: { type: 'boolean' },
    baseVariant: { type: 'boolean' },
    price: { type: 'number' },
    promoLabel: { type: 'string' },
    chargeIntervalInDays: { type: 'number' },
    orderIntervalInDays: { type: 'number' },
    images: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'asset' }] }],
    },
    additionalInformationDetails: {
      type: 'array',
      of: [{ type: 'string' }],
    },
    includeWithAnyOrder: { type: 'boolean' },
    includeWithAnyOrderRequirements: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'productVariant' }] }],
    },
  },
  [asset.asset, {} as ProductVariantStub]
)

export const productVariant = defineDocument(
  'productVariant',
  {
    fields: { type: 'productVariantFields' },
  },
  [productVariantFields.productVariantFields]
)
