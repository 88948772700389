/* eslint "no-prototype-builtins": "off" */
/* eslint "no-async-promise-executor": "off" */
import { useMemo, createContext, useContext, useCallback } from 'react'

const FALLBACK_COLOR = 'transparent'
const extensionMimeTypeMap: Record<string, string> = {
  ['.png']: 'image/png',
  ['.jpeg']: 'image/jpeg',
  ['.jpg']: 'image/jpeg',
  ['.webp']: 'image/webp',
  ['.gif']: 'image/gif',
}

export type DataURL = string | null | undefined
export type DominantColor = string | null | undefined

export type DataUrlCacheContext = {
  ssrCache: Record<string, [DominantColor, DataURL] | null>
}

export const dataUrlCacheObjectFactory = (): Record<
  string,
  [DominantColor, DataURL] | null
> => ({})

export const DataUrlCacheContext = createContext<DataUrlCacheContext>({
  ssrCache: {},
})

export function useDataUrl(url: string | null | undefined) {
  const { ssrCache } = useContext(DataUrlCacheContext)
  const fetcherPromise = useMemo(() => {
    if (!url) {
      return Promise.resolve(null)
    }

    return new Promise<[DominantColor, DataURL] | null>(async (resolve) => {
      if (ssrCache.hasOwnProperty(url)) {
        return resolve(ssrCache[url])
      }

      if (typeof window !== 'undefined') {
        return resolve([FALLBACK_COLOR, url])
      }

      const extension = url.replace(/\?.*$/, '').match(/\.[a-z]+$/)

      if (extension && extensionMimeTypeMap[extension[0]]) {
        const [{ default: fetch }, { default: Vibrant }] = await Promise.all([
          import('node-fetch'),
          import('node-vibrant'),
        ])
        try {
          const result = await (await fetch(url)).buffer()
          const base64 = result.toString('base64')
          const dataUrl = `data:${
            extensionMimeTypeMap[extension[0]]
          };base64,${base64}`
          const palette = await new Promise<string>(async (resolve) => {
            // excluding png for now in case of transparency
            if (extension[0] === '.png') {
              return resolve(FALLBACK_COLOR)
            }

            const builder = new Vibrant(result)

            builder
              .getPalette()
              .then((palette) => {
                resolve(palette.LightVibrant?.hex ?? FALLBACK_COLOR)
              })
              .catch(() => {
                resolve(FALLBACK_COLOR)
              })
          })
          ssrCache[url] = [palette, dataUrl]
          return resolve(ssrCache[url])
        } catch (err) {
          console.error(err)
          ssrCache[url] = [FALLBACK_COLOR, url]
          return resolve(ssrCache[url])
        }
      }

      ssrCache[url] = null
      return resolve(null)
    })
  }, [url, ssrCache])

  const reader = useCallback((): [DominantColor, DataURL] | null => {
    if (url) {
      if (ssrCache.hasOwnProperty(url)) {
        return ssrCache[url]
      }
      if (typeof window !== 'undefined') {
        ssrCache[url] = [FALLBACK_COLOR, url]
        return ssrCache[url]
      }
      throw fetcherPromise
    }
    return null
  }, [url, ssrCache, fetcherPromise])

  return reader
}
