
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    /* eslint "no-unused-vars": "warn" */
import '@typeform/embed/build/css/slider.css';
import '../styles/gorgias.css';
import '../styles/zowie.css';
import '../styles/tolstoy.css';
import '../styles/tailwind.css';
import React, { useCallback, useEffect } from 'react';
import { SpeedInsights } from '@vercel/speed-insights/next';
import NextRouter from 'next/router';
import { AppLinkContext } from '@heights/heights-ui-library';
import { CurrencyContext, ImageContext, ModalContextProvider, } from '@heights/heights-context';
import { PreviewContext, SubscribeFormProvider, CacheContext, cacheObjectFactory, } from '../context';
import { Theme, ThemeProvider } from 'theme-ui';
import { LocaleContext } from '@heights/heights-context';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { theme } from '@heights/heights-theme';
import { SharedPageProps } from '@/types';
import { WithCart } from '@/components/WithCart';
import { DataUrlCacheContext, dataUrlCacheObjectFactory, useAutoLocale, } from '@/hooks';
import { LocaleCodes, Locales } from '@/constants';
import { DEFAULT_CURRENCY_LOCALE } from '@heights/heights-constants';
import { AutoLocaleSource } from '@/hooks/useAutoLocale';
import { ImageComponent } from '@/components/ImageComponent';
import { LinkComponent } from '@/components/LinkComponent';
import { transformLinkHref } from '@/utils';
import { DynamicIconPickerContext } from '@heights/heights-icons';
import { IconComponentsMap } from '@/components/IconPicker';
import { TolstoyWidget } from '@/components';
import { computed } from '@preact/signals-react';
import { useDatahappy } from '@/hooks/useDatahappy';
import { DataURL, DominantColor } from '@/hooks/useDataUrl';
import { redirectToLocale } from '@/utils/browser';
type CustomAppProps = Omit<AppProps<SharedPageProps>, 'pageProps'> & {
    pageProps: SharedPageProps;
};
const cacheObject = {
    ssrCache: cacheObjectFactory(),
    applyCache: (incoming: Record<string, unknown>) => {
        const entries = Object.entries(incoming);
        for (const [key, value] of entries) {
            if (value) {
                cacheObject.ssrCache[key] = value;
            }
        }
    },
};
const dataUrlCacheObject = {
    ssrCache: dataUrlCacheObjectFactory(),
    applyCache: (incoming: Record<string, [
        DominantColor,
        DataURL
    ] | null>) => {
        const entries = Object.entries(incoming);
        for (const [key, value] of entries) {
            if (value) {
                dataUrlCacheObject.ssrCache[key] = value;
            }
        }
    },
};
if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    NextRouter.events.on('hashChangeComplete', () => {
        if (window.location.hash === '#zowie-chatbot') {
            window.Zowie?.open();
        }
    });
    if (process.env.NEXT_PUBLIC_TAPFILIATE_ACCOUNT_ID) {
        import('@tapfiliate/tapfiliate-js').then((Tap) => {
            Tap.init(process.env.NEXT_PUBLIC_TAPFILIATE_ACCOUNT_ID!, undefined, undefined, { cookie_domain: 'yourheights.com' });
        });
    }
}
function MyApp({ Component, router, pageProps }: CustomAppProps) {
    if (typeof window !== 'undefined')
        window.__pageProps = pageProps;
    if (pageProps.ssrCache)
        cacheObject.applyCache(pageProps.ssrCache);
    if (pageProps.dataUrlCache)
        dataUrlCacheObject.applyCache(pageProps.dataUrlCache);
    const { locale, events, pathname } = useRouter();
    const [suggestedLocale, setSuggestedLocale] = React.useState<string | null>(null);
    const autoLocale = useAutoLocale(locale);
    const [datahappy] = useDatahappy();
    const currency = computed(() => {
        const relevantLocale = !!router.locale &&
            Locales.find(({ id, overrideCurrency }) => overrideCurrency && id.toLowerCase() === router.locale!.toLowerCase());
        // only en-us and en-ca have the overrideCurrency flag
        // row/gb will go to fallback (or euro based on geoloc)
        if (relevantLocale && relevantLocale.overrideCurrency) {
            const { id, currencyCode, currencySymbol, currencySymbolNative } = relevantLocale;
            return {
                ...DEFAULT_CURRENCY_LOCALE,
                locale: id,
                currencyCode: currencyCode,
                currencySymbol,
                currencySymbolNative,
            };
        }
        // if we are in the euro-zone -> let's assume euro currency
        // disable this for now for GMC
        /*if (Object.values<string>(EurozoneCountries).includes(country.value)) {
          return {
            ...DEFAULT_CURRENCY_LOCALE,
            locale: country.value,
            currencyCode: 'EUR',
            currencySymbol: '€',
            currencySymbolNative: '€',
          }
        }*/
        return { ...DEFAULT_CURRENCY_LOCALE };
    });
    const currencyContextValue = computed(() => ({
        currency: currency.value,
    }));
    const checkLocaleAndRedirect = useCallback(() => {
        if (typeof window !== 'undefined' && autoLocale) {
            const { source, locale: localeObject } = autoLocale;
            if (locale) {
                if (localeObject.id !== locale && source === AutoLocaleSource.NEXT) {
                    setSuggestedLocale(localeObject.id);
                }
                if (localeObject.id !== locale && source === AutoLocaleSource.STORAGE) {
                    redirectToLocale(localeObject.id);
                }
            }
        }
    }, [locale, autoLocale]);
    useEffect(() => {
        const handleRouteChangeComplete = () => {
            // compared to gatsby this doesn't trigger on initial load
            // which is why we need an additional trigger
            window.dataLayer = window.dataLayer || [];
            datahappy.trackPageview();
        };
        events.on('routeChangeComplete', handleRouteChangeComplete);
        return () => events.off('routeChangeComplete', handleRouteChangeComplete);
    }, [events, datahappy]);
    useEffect(() => {
        checkLocaleAndRedirect();
    }, [pathname, locale, autoLocale, checkLocaleAndRedirect]);
    const sharedContent = (<>
      <PreviewContext.Provider value={{ preview: !!pageProps.preview }}>
        <CacheContext.Provider value={cacheObject}>
          <DataUrlCacheContext.Provider value={dataUrlCacheObject}>
            <DynamicIconPickerContext.Provider value={{ icons: IconComponentsMap }}>
              <CurrencyContext.Provider value={currencyContextValue.value}>
                <ModalContextProvider>
                  <ThemeProvider theme={theme as Theme}>
                    <ImageContext.Provider value={{ ImageComponent }}>
                      <AppLinkContext.Provider value={{
            LinkComponent,
            transform: transformLinkHref,
            redirectLocale: redirectToLocale,
            navigate: (to) => window.location.assign(to),
        }}>
                        <LocaleContext.Provider value={{
            locale: locale ?? LocaleCodes.EN_GB,
            defaultLocale: suggestedLocale ?? locale ?? LocaleCodes.EN_GB,
        }}>
                          <SubscribeFormProvider>
                            <WithCart>
                              {/* depends on globaldata */}
                              <TolstoyWidget />
                              <Component {...pageProps}/>
                              <SpeedInsights />
                            </WithCart>
                          </SubscribeFormProvider>
                        </LocaleContext.Provider>
                      </AppLinkContext.Provider>
                    </ImageContext.Provider>
                  </ThemeProvider>
                </ModalContextProvider>
              </CurrencyContext.Provider>
            </DynamicIconPickerContext.Provider>
          </DataUrlCacheContext.Provider>
        </CacheContext.Provider>
      </PreviewContext.Provider>
    </>);
    return sharedContent;
}
const __Next_Translate__Page__18f39b8a5d3__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__18f39b8a5d3__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  