import React, { createContext, useState, useMemo, ReactNode } from 'react'

export type SubscribeFormContextValue = {
  cookieName: string
  success: boolean
  setSuccess: (success: boolean) => void
}

export const SubscribeFormContext = createContext<SubscribeFormContextValue>({
  cookieName: 'heights-subscribe-modal',
  success: false,
  setSuccess: () => {
    console.error('Not implemented')
  },
})

export const SubscribeFormProvider: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [success, setSuccess] = useState(false)
  const value = useMemo(
    () => ({
      cookieName: 'heights-subscribe-modal',
      success,
      setSuccess,
    }),
    [success, setSuccess]
  )

  return (
    <SubscribeFormContext.Provider value={value}>
      {children}
    </SubscribeFormContext.Provider>
  )
}
