import omit from 'just-omit'
import compact from 'just-compact'
import { ProductsMapModel } from '@/types'
import { useMemo } from 'react'
import { DataSourceType, useData } from './useData'
import { product } from '@/schemas/product'
import { landingPage } from '@/schemas/landingPage'
import {
  FALLBACK_PRODUCT_IMAGE,
  productsInformationFactory,
} from '@/config/products'
import { ProductVariantModel, VariantIdType } from '@/types'
import { decodeShopifyId, shopifyIdsMatch } from '@/utils/shopify'
import { productVariant } from '@/schemas'
import { shopifyVariantPricing } from '@/schemas/shopify'
import { asResolverQuery } from '@/utils/groq'
import { useCurrency } from './useCurrency'
import { roundToPrecision } from '@/utils/math'

const shopifyPricingQuery = shopifyVariantPricing.builder.use()

const [landingPageSubquery, landingPageSubqueryType] = landingPage.builder
  .map((page) => ({
    urlHandle: page.fields.urlHandle?.use(),
  }))
  .pick(['urlHandle'])
  .first()
  .use()
const [variantSummaryQuery, variantSummaryType] = productVariant.builder
  .map((variant) => ({
    shopifyVariantId: variant.fields.shopifyVariantId?.use(),
  }))
  .pick(['_id', 'shopifyVariantId'])
  .use()

const [variantQuery, variantType] = productVariant.builder
  .map((variant) => ({
    images: variant.fields?.images?.resolveIn('fields').use(),
  }))
  .subquery({
    includeWithAnyOrderRequirements: asResolverQuery<typeof variantSummaryType>(
      'fields.includeWithAnyOrderRequirements',
      variantSummaryQuery,
      true
    ),
  })
  .use()
const [query, type] = product.builder
  .map((product) => ({
    dynamicBanners: product.fields.dynamicBanners?.resolveIn('fields').use(),
    productVariantSuggestions: product.fields.productVariantSuggestions
      ?.resolveIn('fields')
      .use(),
    productBundleVariants: product.fields.productBundleVariants
      ?.resolveIn('fields')
      .use(),
    images: product.fields.images?.resolveIn('fields').use(),
  }))
  .subquery({
    landingPage: asResolverQuery<typeof landingPageSubqueryType>(
      'fields.landingPage',
      landingPageSubquery
    ),
    variants: asResolverQuery<typeof variantType>(
      'fields.variants',
      variantQuery,
      true
    ),
  })
  .use()

export function useProducts(): ProductsMapModel {
  const { currencyCode } = useCurrency()
  const reader = useData<typeof type>(DataSourceType.CONTENTFUL, query)
  const shopifyReader = useData<(typeof shopifyPricingQuery)['1']>(
    DataSourceType.SHOPIFY,
    shopifyPricingQuery[0]
  )
  const allProducts = reader()
  const shopifyPrices = shopifyReader()

  return useMemo<ProductsMapModel>(() => {
    const productsInformation = productsInformationFactory()

    if (!allProducts) return productsInformation

    allProducts.forEach((product) => {
      if (!product.fields?.productId || !product.fields.name) return

      // @README this is the variant from which savings will be calculated
      let baseVariantId: VariantIdType | null = null
      // @README this is the variant which will be selected by default
      let defaultVariantId: VariantIdType | null = null
      const variants: ProductVariantModel[] = compact(
        compact(product.variants || []).map<ProductVariantModel | null>(
          (variant) => {
            const isSubscription = !!(
              variant.fields?.orderIntervalInDays &&
              variant.fields?.chargeIntervalInDays
            )
            const productId: VariantIdType | null =
              product?.fields?.shopifyProductId ?? null
            const variantId: VariantIdType | null =
              variant.fields?.shopifyVariantId ?? null
            const monthlyChargeInterval =
              isSubscription && variant.fields?.chargeIntervalInDays
                ? Math.round(variant.fields?.chargeIntervalInDays / 29)
                : null
            const monthlyOrderInterval =
              isSubscription && variant.fields?.orderIntervalInDays
                ? Math.round(variant.fields?.orderIntervalInDays / 29)
                : null
            const chargeIntervalConst = monthlyChargeInterval
              ? monthlyChargeInterval >= 12
                ? ('ANNUAL' as const)
                : monthlyChargeInterval >= 3
                ? ('QUARTER' as const)
                : ('MONTH' as const)
              : null
            const orderIntervalConst = monthlyOrderInterval
              ? monthlyOrderInterval >= 12
                ? ('ANNUAL' as const)
                : monthlyOrderInterval >= 3
                ? ('QUARTER' as const)
                : ('MONTH' as const)
              : null

            const shopifyPricing = shopifyPrices?.find(
              (pricing) =>
                variantId &&
                pricing.variantId &&
                shopifyIdsMatch(pricing.variantId, variantId)
            )

            if (productId && variantId && shopifyPricing) {
              const costPerInterval = shopifyPricing.prices
                ? omit(shopifyPricing.prices, '_type')
                : {}
              const compareCostPerCharge = shopifyPricing.compareAtPrices
                ? omit(shopifyPricing.compareAtPrices, '_type')
                : {}
              const costPerCharge = { ...costPerInterval }

              Object.keys(costPerInterval).forEach((mappedCurrencyCode) => {
                const priceFromShopify = costPerInterval[mappedCurrencyCode]
                const shouldRoundCurrencyToInteger = priceFromShopify % 1 === 0
                const roundingFn = shouldRoundCurrencyToInteger
                  ? Math.round
                  : (input: number) => roundToPrecision(input, 2)

                if (isSubscription && monthlyChargeInterval) {
                  costPerInterval[mappedCurrencyCode] = roundingFn(
                    (costPerInterval[mappedCurrencyCode] ?? 0) /
                      monthlyChargeInterval
                  )
                }
              })

              if (variant.fields?.baseVariant) {
                baseVariantId = variantId
              }

              if (variant.fields?.defaultVariant) {
                defaultVariantId = variantId
              }

              return {
                variantId,
                productId,
                isSubscription,
                contentfulId: variant._id,
                includeWithAnyOrder:
                  variant.fields?.includeWithAnyOrder ?? false,
                includeWithAnyOrderRequirements: compact(
                  variant.includeWithAnyOrderRequirements?.map((entry) =>
                    entry.shopifyVariantId
                      ? { variantId: entry.shopifyVariantId }
                      : null
                  ) ?? []
                ),
                planId: variant.fields?.shopifyPlanId ?? null,
                costPerIntervalFrequency: costPerInterval,
                costPerChargeFrequency: costPerCharge,
                compareCostPerChargeFrequency: compareCostPerCharge,
                highlightLabel: variant.fields?.name ?? '',
                promoLabel: variant.fields?.promoLabel ?? '',
                name: product.fields?.name ?? '',
                subtitle: variant.fields?.subtitle ?? '',
                description: variant.fields?.description ?? '',
                additionalInformation:
                  variant.fields?.additionalInformationDetails ?? [],
                saving: '',
                chargeIntervalFrequency:
                  variant.fields?.chargeIntervalInDays ?? null,
                orderIntervalFrequency:
                  variant.fields?.orderIntervalInDays ?? null,
                chargeIntervalType: chargeIntervalConst ?? null,
                orderIntervalType: orderIntervalConst ?? null,
                orderIntervalUnit: 'day',
                featuredImage: shopifyPricing.featuredImage,
                images: compact(variant.images ?? []).map((image) => ({
                  width: image?.file?.details?.image?.width,
                  height: image?.file?.details?.image?.height,
                  url: image?.file?.url,
                  title: image?.title,
                  description: image?.description,
                  contentType: image?.file?.contentType,
                })),
              } as ProductVariantModel
            }
            return null
          }
        )
      )

      // use the defaultVariantId if there is no explicit base variant defined
      if (!baseVariantId && defaultVariantId) {
        baseVariantId = defaultVariantId
      }

      const baseVariant = baseVariantId
        ? variants.find((variant) => variant.variantId === baseVariantId)
        : null
      productsInformation[product.fields.productId] = {
        name: product.fields.name,
        href: product.landingPage?.urlHandle
          ? `/${product.landingPage?.urlHandle}/`
          : '/',
        contentfulId: product._id,
        productId: product.fields?.productId,
        shopifyProductId: product.fields.shopifyProductId ?? '',
        productImage: FALLBACK_PRODUCT_IMAGE,
        productImages: compact(product.images ?? []).map((image) => ({
          width: image?.file?.details?.image?.width,
          height: image?.file?.details?.image?.height,
          url: image?.file?.url,
          title: image?.title,
          description: image?.description,
          contentType: image?.file?.contentType,
        })),
        defaultVariantId: defaultVariantId ?? variants?.[0]?.variantId ?? null,
        variants: baseVariant
          ? variants.map((variant) => {
              if (
                variant.variantId !== baseVariantId &&
                !!variant.isSubscription
              ) {
                const fromBaseMultiplier: number =
                  variant.chargeIntervalFrequency /
                  (baseVariant.chargeIntervalFrequency ?? 29)
                const cost =
                  variant.costPerIntervalFrequency?.[currencyCode] ||
                  variant.costPerIntervalFrequency.GBP
                const baseCost =
                  baseVariant.costPerIntervalFrequency?.[currencyCode] ||
                  baseVariant.costPerIntervalFrequency.GBP
                const saving = Math.round(((baseCost - cost) / baseCost) * 100)

                const baseCostPriceMap = Object.entries(
                  baseVariant.costPerChargeFrequency
                ).map(([key, baseCharge]) => [
                  key,
                  baseCharge * fromBaseMultiplier,
                ])

                return {
                  ...variant,
                  saving: variant.saving || `Save ${saving}%`,
                  baseCostPerChargeFrequency:
                    Object.fromEntries(baseCostPriceMap),
                }
              }

              return variant
            })
          : variants,
        suggestedVariants: compact(
          product.productVariantSuggestions?.map((variant) => {
            if (variant?.shopifyVariantId) {
              return {
                variantId: variant.shopifyVariantId,
                planId: variant.shopifyPlanId ?? null,
              }
            }
            return null
          }) ?? []
        ),
        bundleVariants: compact(
          product.productBundleVariants?.map((variant) => {
            if (variant?.shopifyVariantId) {
              return {
                variantId: variant.shopifyVariantId,
                planId: variant.shopifyPlanId ?? null,
              }
            }
            return null
          }) ?? []
        ),
        dynamicBanners: compact(
          (product.dynamicBanners ?? []).map((entry) =>
            entry?.content
              ? {
                  ...entry,
                  content: entry.content,
                }
              : null
          )
        ),
      }

      // @README this should be updated to always use shopifyProductId
      // instead of productId -> we should move away from the manual product IDs
      if (product.fields.shopifyProductId) {
        productsInformation[product.fields.shopifyProductId] = {
          ...productsInformation[product.fields.productId],
        }
        // overriding old hardcoded config
        productsInformation[decodeShopifyId(product.fields.shopifyProductId)] =
          { ...productsInformation[product.fields.productId] }
      }
    })

    return productsInformation
  }, [allProducts, shopifyPrices, currencyCode])
}
