import { IconNames } from '@heights/heights-icons'
import dynamic from 'next/dynamic'

const DynamicAccountIcon = dynamic(() => import('./icons/AccountIcon'))
const DynamicArrowDownIcon = dynamic(() => import('./icons/ArrowDownIcon'))
const DynamicArrowUpBoldIcon = dynamic(() => import('./icons/ArrowUpBoldIcon'))
const DynamicAudioPauseIcon = dynamic(() => import('./icons/AudioPauseIcon'))
const DynamicAudioPlayIcon = dynamic(() => import('./icons/AudioPlayIcon'))
const DynamicBasketIcon = dynamic(() => import('./icons/BasketIcon'))
const DynamicBetterRecallIcon = dynamic(
  () => import('./icons/BetterRecallIcon')
)
const DynamicBurgerIcon = dynamic(() => import('./icons/BurgerIcon'))
const DynamicCaloriesIcon = dynamic(() => import('./icons/CaloriesIcon'))
const DynamicCapsuleIcon = dynamic(() => import('./icons/CapsuleIcon'))
const DynamicCardioHealthIcon = dynamic(
  () => import('./icons/CardioHealthIcon')
)
const DynamicCarouselArrowIcon = dynamic(
  () => import('./icons/CarouselArrowIcon')
)
const DynamicCertifiedBCorporationIcon = dynamic(
  () => import('./icons/CertifiedBCorporationIcon')
)
const DynamicCheckIcon = dynamic(() => import('./icons/CheckIcon'))
const DynamicCheckmarkIcon = dynamic(() => import('./icons/CheckmarkIcon'))
const DynamicChevronDownIcon = dynamic(() => import('./icons/ChevronDownIcon'))
const DynamicClapIcon = dynamic(() => import('./icons/ClapIcon'))
const DynamicClinicHealthIcon = dynamic(
  () => import('./icons/ClinicHealthIcon')
)
const DynamicCloseIcon = dynamic(() => import('./icons/CloseIcon'))
const DynamicCommunityIcon = dynamic(() => import('./icons/CommunityIcon'))
const DynamicDocumentIcon = dynamic(() => import('./icons/DocumentIcon'))
const DynamicEmptyBasketIcon = dynamic(() => import('./icons/EmptyBasketIcon'))
const DynamicExpertsIcon = dynamic(() => import('./icons/ExpertsIcon'))
const DynamicFacebookIcon = dynamic(() => import('./icons/FacebookIcon'))
const DynamicFaqIcon = dynamic(() => import('./icons/FaqIcon'))
const DynamicFastShippingIcon = dynamic(
  () => import('./icons/FastShippingIcon')
)
const DynamicFightsFatigueIcon = dynamic(
  () => import('./icons/FightsFatigueIcon')
)
const DynamicFilledBasketIcon = dynamic(
  () => import('./icons/FilledBasketIcon')
)
const DynamicFlagUkIcon = dynamic(() => import('./icons/FlagUkIcon'))
const DynamicFlagUsIcon = dynamic(() => import('./icons/FlagUsIcon'))
const DynamicFocusIcon = dynamic(() => import('./icons/FocusIcon'))
const DynamicFollowLinkIcon = dynamic(() => import('./icons/FollowLinkIcon'))
const DynamicFreeShippingIcon = dynamic(
  () => import('./icons/FreeShippingIcon')
)
const DynamicGlobalIcon = dynamic(() => import('./icons/GlobalIcon'))
const DynamicGlutenFree = dynamic(() => import('./icons/GlutenFree'))
const DynamicGutHealthIcon = dynamic(() => import('./icons/GutHealthIcon'))
const DynamicHippocampusIcon = dynamic(() => import('./icons/HippocampusIcon'))
const DynamicImmunityIcon = dynamic(() => import('./icons/ImmunityIcon'))
const DynamicImprovesMoodIcon = dynamic(
  () => import('./icons/ImprovesMoodIcon')
)
const DynamicIngredientsIcon = dynamic(() => import('./icons/IngredientsIcon'))
const DynamicInstagramIcon = dynamic(() => import('./icons/InstagramIcon'))
const DynamicJournalIcon = dynamic(() => import('./icons/JournalIcon'))
const DynamicLearningIcon = dynamic(() => import('./icons/LearningIcon'))
const DynamicLhAllIcon = dynamic(() => import('./icons/LhAllIcon'))
const DynamicLhBrainHealthIcon = dynamic(
  () => import('./icons/LhBrainHealthIcon')
)
const DynamicLhBrainScienceIcon = dynamic(
  () => import('./icons/LhBrainScienceIcon')
)
const DynamicLhClubhouseIcon = dynamic(() => import('./icons/LhClubhouseIcon'))
const DynamicLhIngredientsIcon = dynamic(
  () => import('./icons/LhIngredientsIcon')
)
const DynamicLhMentalWellbeingIcon = dynamic(
  () => import('./icons/LhMentalWellbeingIcon')
)
const DynamicLhNutritionIcon = dynamic(() => import('./icons/LhNutritionIcon'))
const DynamicLhPodcastIcon = dynamic(() => import('./icons/LhPodcastIcon'))
const DynamicLinkedinIcon = dynamic(() => import('./icons/LinkedinIcon'))
const DynamicLockIcon = dynamic(() => import('./icons/LockIcon'))
const DynamicMinusIcon = dynamic(() => import('./icons/MinusIcon'))
const DynamicMinusLightIcon = dynamic(() => import('./icons/MinusLightIcon'))
const DynamicMonthlySubscriptionIcon = dynamic(
  () => import('./icons/MonthlySubscriptionIcon')
)
const DynamicMoreEnergyIcon = dynamic(() => import('./icons/MoreEnergyIcon'))
const DynamicMoreIcon = dynamic(() => import('./icons/MoreIcon'))
const DynamicNervousSystemIcon = dynamic(
  () => import('./icons/NervousSystemIcon')
)
const DynamicNoAllergens = dynamic(() => import('./icons/NoAllergens'))
const DynamicNoContaminants = dynamic(() => import('./icons/NoContaminants'))
const DynamicNoFillers = dynamic(() => import('./icons/NoFillers'))
const DynamicNonGmo = dynamic(() => import('./icons/NonGmo'))
const DynamicPaymentAmexIcon = dynamic(() => import('./icons/PaymentAmexIcon'))
const DynamicPaymentApplePayIcon = dynamic(
  () => import('./icons/PaymentApplePayIcon')
)
const DynamicPaymentDinersClubIcon = dynamic(
  () => import('./icons/PaymentDinersClubIcon')
)
const DynamicPaymentDiscoverIcon = dynamic(
  () => import('./icons/PaymentDiscoverIcon')
)
const DynamicPaymentGooglePayIcon = dynamic(
  () => import('./icons/PaymentGooglePayIcon')
)
const DynamicPaymentMastercardIcon = dynamic(
  () => import('./icons/PaymentMastercardIcon')
)
const DynamicPaymentPaypalIcon = dynamic(
  () => import('./icons/PaymentPaypalIcon')
)
const DynamicPaymentVisaIcon = dynamic(() => import('./icons/PaymentVisaIcon'))
const DynamicPlantBased = dynamic(() => import('./icons/PlantBased'))
const DynamicPlusIcon = dynamic(() => import('./icons/PlusIcon'))
const DynamicPlusLightIcon = dynamic(() => import('./icons/PlusLightIcon'))
const DynamicPodcastIcon = dynamic(() => import('./icons/PodcastIcon'))
const DynamicReducesStressIcon = dynamic(
  () => import('./icons/ReducesStressIcon')
)
const DynamicResearchIcon = dynamic(() => import('./icons/ResearchIcon'))
const DynamicServingsIcon = dynamic(() => import('./icons/ServingsIcon'))
const DynamicShareIcon = dynamic(() => import('./icons/ShareIcon'))
const DynamicSlowsCognitiveDeclineIcon = dynamic(
  () => import('./icons/SlowsCognitiveDeclineIcon')
)
const DynamicSoundSleepIcon = dynamic(() => import('./icons/SoundSleepIcon'))
const DynamicSpinnerIcon = dynamic(() => import('./icons/SpinnerIcon'))
const DynamicStarCheckmarkIcon = dynamic(
  () => import('./icons/StarCheckmarkIcon')
)
const DynamicSubsCancelIcon = dynamic(() => import('./icons/SubsCancelIcon'))
const DynamicSubsDeliveryIcon = dynamic(
  () => import('./icons/SubsDeliveryIcon')
)
const DynamicSubsLetterboxIcon = dynamic(
  () => import('./icons/SubsLetterboxIcon')
)
const DynamicSuccessIcon = dynamic(() => import('./icons/SuccessIcon'))
const DynamicTimeIcon = dynamic(() => import('./icons/TimeIcon'))
const DynamicTrashIcon = dynamic(() => import('./icons/TrashIcon'))
const DynamicTrustpilotLogoIcon = dynamic(
  () => import('./icons/TrustpilotLogoIcon')
)
const DynamicTwitterIcon = dynamic(() => import('./icons/TwitterIcon'))
const DynamicUserIcon = dynamic(() => import('./icons/UserIcon'))
const DynamicVideoPlayFilledIcon = dynamic(
  () => import('./icons/VideoPlayFilledIcon')
)
const DynamicVideoPlayIcon = dynamic(() => import('./icons/VideoPlayIcon'))
const DynamicYoutubeIcon = dynamic(() => import('./icons/YoutubeIcon'))

export const IconComponentsMap = {
  [IconNames.account]: DynamicAccountIcon,
  [IconNames.arrowDown]: DynamicArrowDownIcon,
  [IconNames.arrowUpBold]: DynamicArrowUpBoldIcon,
  [IconNames.audioPause]: DynamicAudioPauseIcon,
  [IconNames.audioPlay]: DynamicAudioPlayIcon,
  [IconNames.basket]: DynamicBasketIcon,
  [IconNames.betterRecall]: DynamicBetterRecallIcon,
  [IconNames.burger]: DynamicBurgerIcon,
  [IconNames.calories]: DynamicCaloriesIcon,
  [IconNames.capsule]: DynamicCapsuleIcon,
  [IconNames.cardioHealth]: DynamicCardioHealthIcon,
  [IconNames.carouselArrow]: DynamicCarouselArrowIcon,
  [IconNames.certifiedBCorporation]: DynamicCertifiedBCorporationIcon,
  [IconNames.check]: DynamicCheckIcon,
  [IconNames.checkmark]: DynamicCheckmarkIcon,
  [IconNames.chevronDown]: DynamicChevronDownIcon,
  [IconNames.clap]: DynamicClapIcon,
  [IconNames.clinicHealth]: DynamicClinicHealthIcon,
  [IconNames.close]: DynamicCloseIcon,
  [IconNames.community]: DynamicCommunityIcon,
  [IconNames.document]: DynamicDocumentIcon,
  [IconNames.emptyBasket]: DynamicEmptyBasketIcon,
  [IconNames.experts]: DynamicExpertsIcon,
  [IconNames.facebook]: DynamicFacebookIcon,
  [IconNames.faq]: DynamicFaqIcon,
  [IconNames.fastShipping]: DynamicFastShippingIcon,
  [IconNames.fightsFatigue]: DynamicFightsFatigueIcon,
  [IconNames.filledBasket]: DynamicFilledBasketIcon,
  [IconNames.flagUk]: DynamicFlagUkIcon,
  [IconNames.flagUs]: DynamicFlagUsIcon,
  [IconNames.focus]: DynamicFocusIcon,
  [IconNames.followLink]: DynamicFollowLinkIcon,
  [IconNames.freeShipping]: DynamicFreeShippingIcon,
  [IconNames.global]: DynamicGlobalIcon,
  [IconNames.glutenFree]: DynamicGlutenFree,
  [IconNames.gutHealth]: DynamicGutHealthIcon,
  [IconNames.hippocampus]: DynamicHippocampusIcon,
  [IconNames.immunity]: DynamicImmunityIcon,
  [IconNames.improvesMood]: DynamicImprovesMoodIcon,
  [IconNames.ingredients]: DynamicIngredientsIcon,
  [IconNames.instagram]: DynamicInstagramIcon,
  [IconNames.journal]: DynamicJournalIcon,
  [IconNames.learning]: DynamicLearningIcon,
  [IconNames.lhAll]: DynamicLhAllIcon,
  [IconNames.lhBrainHealth]: DynamicLhBrainHealthIcon,
  [IconNames.lhBrainScience]: DynamicLhBrainScienceIcon,
  [IconNames.lhClubhouse]: DynamicLhClubhouseIcon,
  [IconNames.lhIngredients]: DynamicLhIngredientsIcon,
  [IconNames.lhMentalWellbeing]: DynamicLhMentalWellbeingIcon,
  [IconNames.lhNutrition]: DynamicLhNutritionIcon,
  [IconNames.lhPodcast]: DynamicLhPodcastIcon,
  [IconNames.linkedin]: DynamicLinkedinIcon,
  [IconNames.lock]: DynamicLockIcon,
  [IconNames.minus]: DynamicMinusIcon,
  [IconNames.minusLight]: DynamicMinusLightIcon,
  [IconNames.monthlySubscription]: DynamicMonthlySubscriptionIcon,
  [IconNames.moreEnergy]: DynamicMoreEnergyIcon,
  [IconNames.more]: DynamicMoreIcon,
  [IconNames.nervousSystem]: DynamicNervousSystemIcon,
  [IconNames.noAllergens]: DynamicNoAllergens,
  [IconNames.noContaminants]: DynamicNoContaminants,
  [IconNames.noFillers]: DynamicNoFillers,
  [IconNames.nonGmo]: DynamicNonGmo,
  [IconNames.paymentAmex]: DynamicPaymentAmexIcon,
  [IconNames.paymentApplePay]: DynamicPaymentApplePayIcon,
  [IconNames.paymentDinersClub]: DynamicPaymentDinersClubIcon,
  [IconNames.paymentDiscover]: DynamicPaymentDiscoverIcon,
  [IconNames.paymentGooglePay]: DynamicPaymentGooglePayIcon,
  [IconNames.paymentMastercard]: DynamicPaymentMastercardIcon,
  [IconNames.paymentPaypal]: DynamicPaymentPaypalIcon,
  [IconNames.paymentVisa]: DynamicPaymentVisaIcon,
  [IconNames.plantBased]: DynamicPlantBased,
  [IconNames.plus]: DynamicPlusIcon,
  [IconNames.plusLight]: DynamicPlusLightIcon,
  [IconNames.podcast]: DynamicPodcastIcon,
  [IconNames.reducesStress]: DynamicReducesStressIcon,
  [IconNames.research]: DynamicResearchIcon,
  [IconNames.servings]: DynamicServingsIcon,
  [IconNames.share]: DynamicShareIcon,
  [IconNames.slowsCognitiveDecline]: DynamicSlowsCognitiveDeclineIcon,
  [IconNames.soundSleep]: DynamicSoundSleepIcon,
  [IconNames.spinner]: DynamicSpinnerIcon,
  [IconNames.starCheckmark]: DynamicStarCheckmarkIcon,
  [IconNames.subsCancel]: DynamicSubsCancelIcon,
  [IconNames.subsDelivery]: DynamicSubsDeliveryIcon,
  [IconNames.subsLetterbox]: DynamicSubsLetterboxIcon,
  [IconNames.success]: DynamicSuccessIcon,
  [IconNames.time]: DynamicTimeIcon,
  [IconNames.trash]: DynamicTrashIcon,
  [IconNames.trustpilotLogo]: DynamicTrustpilotLogoIcon,
  [IconNames.twitter]: DynamicTwitterIcon,
  [IconNames.user]: DynamicUserIcon,
  [IconNames.videoPlayFilled]: DynamicVideoPlayFilledIcon,
  [IconNames.videoPlay]: DynamicVideoPlayIcon,
  [IconNames.youtube]: DynamicYoutubeIcon,
}
