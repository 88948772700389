import React from 'react'
import Script from 'next/script'
import { useABSplit, useGlobalData } from '@/hooks'

export function TolstoyWidget() {
  const globalData = useGlobalData()
  const tolstoyExperimentId = globalData?.fields?.tolstoyExperimentId
  const loadTolstoySplit = useABSplit(tolstoyExperimentId ?? '')
  // always load tolstoy by default - variant 1 is disabling it
  const loadTolstoy = loadTolstoySplit === 1 ? false : true

  return process.env.NEXT_PUBLIC_TOLSTOY_APP_KEY &&
    (loadTolstoy || !tolstoyExperimentId) ? (
    <Script
      id="tolstoy"
      strategy="lazyOnload"
      src="https://widget.gotolstoy.com/widget/widget.js"
    />
  ) : null
}
