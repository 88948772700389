import { startTransition, useEffect, useRef, useState } from 'react'
import { Cookies } from '@/constants'
import { localStorage } from '@/utils/localStorage'

export function getOrAssignABSplit(id: string) {
  const url = new URL(window.location.toString())

  const assignedValue =
    url.searchParams.get(`${Cookies.AB_SPLIT_ASSIGNMENT}.${id}`) ??
    localStorage.getItem(`${Cookies.AB_SPLIT_ASSIGNMENT}.${id}`)

  // use assigned AB variant
  if (assignedValue === '0' || assignedValue === '1') {
    const assignedVariant = Number(assignedValue) as 0 | 1
    return assignedVariant
  } else {
    // consume value from AB split pool
    const nextVariant = Math.random() * 100 < 50 ? 0 : 1
    localStorage.setItem(
      `${Cookies.AB_SPLIT_ASSIGNMENT}.${id}`,
      String(nextVariant)
    )
    return nextVariant
  }
}

export function useABSplit(id: string) {
  const [variant, setVariant] = useState<null | 0 | 1>(null)

  const variantRef = useRef(variant)
  variantRef.current = variant

  useEffect(() => {
    startTransition(() => {
      if (typeof window !== 'undefined') {
        const assignedVariant = getOrAssignABSplit(id)
        if (assignedVariant !== variantRef.current) {
          setVariant(assignedVariant)
        }
      }
    })
  }, [id])

  useEffect(() => {
    if (typeof variant === 'number') {
      window.datahappy?.ready(() => {
        window.datahappy?.getAnonymousId().then((anonId) => {
          window.mixpanel?.track('$experiment_started', {
            $device_id: anonId,
            'Experiment name': id,
            'Variant name': variant,
          })
        })
      })
    }
  }, [id, variant])

  return variant
}
