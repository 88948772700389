import { createContext } from 'react'

export type CacheContextValue = {
  ssrCache: typeof globalSsrCache
}

export const globalSsrCache: Record<string, any> = {}
export const cacheObjectFactory = (): Record<string, any> => ({})

export const CacheContext = createContext<CacheContextValue>({
  ssrCache: {},
})
