import { decode } from 'js-base64'

export function decodeShopifyId(id: string | number): number
export function decodeShopifyId(
  id: string | number,
  parseAsNumber: false
): string
export function decodeShopifyId(id: string | number, parseAsNumber?: false) {
  if (typeof id === 'number') {
    return id
  }

  if (parseAsNumber !== false) {
    const parsedId = parseFloat(id)
    if (!isNaN(parsedId)) {
      return parsedId
    }
  }

  const decoded = (id.startsWith('gid://shopify/') ? id : decode(id)).replace(
    /^gid:\/\/shopify\/\w+\//,
    ''
  )

  if (parseAsNumber === false) {
    return decoded
  }

  const rawId = parseFloat(decoded)
  return isNaN(rawId) ? decoded : rawId
}
