import { backgroundColors } from '@/contentful/utils/commonValues'
import { IconNames } from '@heights/heights-icons'
import { defineDocument, defineObject } from 'sanity-typed-queries'
import { asset } from './asset'
import { JsonType } from './JsonType'

const subscriptionSectionFields = defineObject(
  'subscriptionSectionFields',
  {
    reference: { type: 'string' },
    trackingId: { type: 'string' },
    title: { type: 'string' },
    description: { type: 'json' },
    listId: { type: 'string' },
    buttonText: { type: 'string' },
    placeholderText: { type: 'string' },
    successTitle: { type: 'string' },
    successMessage: { type: 'json' },
    backgroundColor: { type: 'string', options: { list: backgroundColors } },
    image: { type: 'reference', to: [{ type: 'asset' }] },
    successIcon: {
      type: 'string',
      options: { list: Object.values(IconNames) },
    },
    subscriptionTerms: { type: 'string' },
    fields: {
      type: 'array',
      of: [
        {
          type: 'string',
          options: {
            list: ['firstname' as const, 'email' as const, 'phone' as const],
          },
        },
      ],
    },
  },
  [{} as JsonType, asset.asset]
)

export const subscriptionSection = defineDocument(
  'subscriptionSection',
  {
    fields: { type: 'subscriptionSectionFields' },
  },
  [subscriptionSectionFields.subscriptionSectionFields]
)
