import { useStorefrontCart } from '@/hooks/useStorefrontCart'
import { createContext } from 'react'

export type CartContextValue = {
  storefront: ReturnType<typeof useStorefrontCart>
}

export const CartContext = createContext<CartContextValue>({
  storefront: {
    cart: null,
    updating: false,
    addProducts: () => Promise.resolve(null),
    removeProducts: () => Promise.resolve(null),
    updateDiscountCodes: () => Promise.resolve(null),
    updateProducts: () => Promise.resolve(null),
  },
})
