import {
  backgroundColors,
  foregroundColors,
} from '@/contentful/utils/commonValues'
import { HeroStyle } from '@heights/heights-ui-components'
import { defineDocument, defineObject } from 'sanity-typed-queries'
import { asset } from './asset'
import { ctaAction } from './ctaAction'
import { JsonType } from './JsonType'
import { ReferenceType } from './ReferenceType'

export type HeroTypeStub = {
  _type: 'hero'
  _id: string
  _rev: string
  fields: {
    reference?: string
    trackingId?: string
    version?: 'original' | 'v2'
    heroStyle?: HeroStyle
    label?: string
    title?: string
    textAlignment?: 'left' | 'center'
    contentAlignment?: 'top' | 'center'
    imageAlignment?: 'top' | 'center' | 'bottom'
    mobileImageAlignment?: 'top' | 'center' | 'bottom'
    showTrustpilot?: boolean
    hideTrustpilotLink?: boolean
    benefits?: string[]
    subtitle?: JsonType
    ctaText?: string
    ctaLink?: string
    ctaAction?: ReferenceType | typeof ctaAction.ctaAction
    ctaActions?: ReferenceType[] | typeof ctaAction.ctaAction[]
    foregroundColor?: typeof foregroundColors[number]
    backgroundColor?: typeof backgroundColors[number]
    contentBackgroundColor?: typeof backgroundColors[number]
    backgroundImage?: ReferenceType | typeof asset.asset
    mobileBackgroundImage?: ReferenceType | typeof asset.asset
    backgroundVideo?: ReferenceType | typeof asset.asset
    foregroundImage?: ReferenceType | typeof asset.asset
    mobileForegroundImage?: ReferenceType | typeof asset.asset
    foregroundVideoUrl?: string
    imageOverlayOpacity?: number
    showScrollHint?: boolean
    buttonDownText?: string
    components?: ReferenceType[] | HeroTypeStub[]
  }
}

const heroFields = defineObject(
  'heroFields',
  {
    reference: { type: 'string' },
    trackingId: { type: 'string' },
    version: {
      type: 'string',
      options: { list: ['original' as const, 'v2' as const] },
    },
    heroStyle: {
      type: 'string',
      options: { list: Object.values(HeroStyle) },
    },
    label: { type: 'string' },
    title: { type: 'string' },
    textAlignment: {
      type: 'string',
      options: { list: ['left' as const, 'center' as const] },
    },
    contentAlignment: {
      type: 'string',
      options: { list: ['top' as const, 'center' as const] },
    },
    imageAlignment: {
      type: 'string',
      options: { list: ['top' as const, 'center' as const, 'bottom' as const] },
    },
    mobileImageAlignment: {
      type: 'string',
      options: { list: ['top' as const, 'center' as const, 'bottom' as const] },
    },
    showTrustpilot: {
      type: 'boolean',
    },
    hideTrustpilotLink: {
      type: 'boolean',
    },
    benefits: {
      type: 'array',
      of: [{ type: 'string' }],
    },
    subtitleRT: { type: 'json' },
    ctaText: { type: 'string' },
    ctaLink: { type: 'string' },
    ctaAction: { type: 'reference', to: [{ type: 'ctaAction' }] },
    ctaActions: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'ctaAction' }] }],
    },
    foregroundColor: {
      type: 'string',
      options: { list: foregroundColors },
    },
    backgroundColor: {
      type: 'string',
      options: { list: backgroundColors },
    },
    contentBackgroundColor: {
      type: 'string',
      options: { list: backgroundColors },
    },
    backgroundImage: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    mobileBackgroundImage: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    backgroundVideo: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    foregroundImage: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    mobileForegroundImage: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    foregroundVideoUrl: { type: 'string' },
    imageOverlayOpacity: { type: 'number' },
    showScrollHint: { type: 'boolean' },
    buttonDownText: { type: 'string' },
    components: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'hero' }] }],
    },
  },
  [{} as JsonType, {} as HeroTypeStub, ctaAction.ctaAction, asset.asset]
)

export const hero = defineDocument(
  'hero',
  {
    fields: {
      type: 'heroFields',
    },
  },
  [heroFields.heroFields]
)
