import { CartContext } from '@/context'
import { CartFieldsFragmentFragment } from '@/graphql/storefront'
import { useStorefrontCart } from '@/hooks/useStorefrontCart'
import React, { ReactNode, useEffect, useMemo } from 'react'

const trackRefersionCartId = (cart: CartFieldsFragmentFragment) => {
  if (typeof window !== 'undefined' && cart?.id) {
    const rfsn = {
      cart: cart.id.replace(/gid:\/\/shopify\/Cart\//, ''),
      id: localStorage.getItem('rfsn_v4_id'),
      url: window.location.href,
      aid: localStorage.getItem('rfsn_v4_aid'),
      cs: localStorage.getItem('rfsn_v4_cs'),
    }
    window.r?.sendCheckoutEvent(rfsn.cart, rfsn.id, rfsn.url, rfsn.aid, rfsn.cs)
  }
}

export const WithCart: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const storefrontCart = useStorefrontCart()

  const contextValue = useMemo(
    () => ({
      storefront: storefrontCart,
    }),
    [storefrontCart]
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const updateRefersionCartId = () => {
        if (storefrontCart.cart?.id) {
          trackRefersionCartId(storefrontCart.cart)
        }
      }
      window.addEventListener('refersionloaded', updateRefersionCartId)
      updateRefersionCartId()
      return () => {
        window.removeEventListener('refersionloaded', updateRefersionCartId)
      }
    }
  }, [storefrontCart.cart])

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  )
}
