import { defineDocument, defineObject } from 'sanity-typed-queries'
import { asset } from './asset'
import { hero } from './hero'
import { JsonType } from './JsonType'
import { pdpHero } from './pdpHero'
import { product } from './product'

export type LandingPageTypeStub = {
  _type: 'landingPage'
  _id: string
  _rev: string
  fields: {
    title?: string
    urlHandle?: string
  }
}

const landingPageFields = defineObject(
  'landingPageFields',
  {
    enabledTranslations: {
      type: 'array',
      of: [{ type: 'string' }],
    },
    title: { type: 'string' },
    urlHandle: { type: 'string' },
    theme: {
      type: 'string',
      options: {
        list: [
          'original' as const,
          'v2' as const,
          'teal' as const,
          'green' as const,
          'white' as const,
        ],
      },
    },
    hero: {
      type: 'reference',
      to: [{ type: 'hero' }, { type: 'pdpHero' }],
    },
    sections: {
      type: 'array',
      of: [
        {
          type: 'reference',
          to: [],
        },
      ],
    },
    metaDescription: { type: 'text' },
    metaImage: { type: 'reference', to: [{ type: 'asset' }] },
    oldUrlPaths: { type: 'array', of: [{ type: 'string' }] },
    isPDP: { type: 'boolean' },
    isEmbeddedPage: { type: 'boolean' },
    hideFromSearchEngines: { type: 'boolean' },
    navTheme: {
      type: 'string',
      options: { list: ['brand' as const, 'light' as const] },
    },
    navMode: {
      type: 'string',
      options: { list: ['full' as const, 'basic' as const] },
    },
    transparentNav: { type: 'boolean' },
    hideAnnouncementBanner: { type: 'boolean' },
    miniBannerContent: { type: 'json' },
    pageProduct: { type: 'reference', to: [{ type: 'product' }] },
    trackingBreadcrumbId: { type: 'string' },
  },
  [{} as JsonType, hero.hero, pdpHero.pdpHero, asset.asset, product.product]
)

export const landingPage = defineDocument(
  'landingPage',
  {
    fields: {
      type: 'landingPageFields',
    },
  },
  [landingPageFields.landingPageFields, product.product]
)
