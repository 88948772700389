import Url from 'url-parse'
import { isMatch } from 'matcher'
import { GlobalPaths, Locales } from '@/constants'
import compact from 'just-compact'

export const transformLinkHref = (href: string, locale: string) => {
  if (!href) return ''

  const isExcluded = isMatch(href, GlobalPaths)

  if (href && !isExcluded) {
    // pure hash links should not be transformed since they are always relative
    const isPureHashlink = Boolean(href?.match(/^#/))
    if (isPureHashlink) return href

    const defaultLocaleId = Locales[0].id.toLowerCase()
    const supportedLocales = Locales.map(({ id }) => id.toLowerCase())
    const url = new Url(href)
    let transformedPathname = compact(url.pathname.split('/'))
      .filter(
        (part, index) =>
          index !== 0 || !supportedLocales.includes(part.toLowerCase())
      )
      .join('/')

    if (
      locale !== defaultLocaleId &&
      transformedPathname.split('/')[0] !== 'blog'
    ) {
      transformedPathname = [locale.toLowerCase(), transformedPathname].join(
        '/'
      )
    }
    url.set('pathname', transformedPathname || '/')

    const newHref = url.toString().replace(url.origin, '')

    return newHref || '/'
  }
  return href
}
