import { defineDocument, defineObject } from 'sanity-typed-queries'
import { nutrient } from './nutrient'
import { nutrientServing } from './nutrientServing'

const supplementFactsFields = defineObject(
  'supplementFactsFields',
  {
    reference: { type: 'string' },
    nutrients: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'nutrient' }] }],
    },
    nutrientServings: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'nutrientServing' }] }],
    },
    modalTitle: { type: 'string' },
    modalTableLabel: { type: 'string' },
  },
  [nutrient.nutrient, nutrientServing.nutrientServing]
)

export const supplementFacts = defineDocument(
  'nutrientList',
  {
    fields: { type: 'supplementFactsFields' },
  },
  [supplementFactsFields.supplementFactsFields]
)
