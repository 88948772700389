import { defineDocument, defineObject } from 'sanity-typed-queries'
import { productVariant } from './productVariant'
import { asset } from './asset'
import { JsonType } from './JsonType'
import { LandingPageTypeStub } from './landingPage'
import { theme } from './theme'
import { productDynamicBannerEntry } from './productDynamicBannerEntry'

const productFields = defineObject(
  'productFields',
  {
    // @deprecated we should deprecate this field and base all data on the shopify ID
    // this only works in a system where we have 1 contentful product per shopify ID
    productId: { type: 'number' },
    reference: { type: 'string' },
    name: { type: 'string' },
    label: { type: 'string' },
    labelTheme: { type: 'reference', to: [{ type: 'theme' }] },
    description: { type: 'json' },
    images: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'asset' }] }],
    },
    variants: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'productVariant' }] }],
    },
    productVariantSuggestions: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'productVariant' }] }],
    },
    productBundleVariants: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'productVariant' }] }],
    },
    landingPage: {
      type: 'reference',
      to: [{ type: 'landingPage' }],
    },
    shopifyProductId: {
      type: 'string',
    },
    dynamicBanners: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'productDynamicBannerEntry' }] }],
    },
  },
  [
    {} as JsonType,
    {} as LandingPageTypeStub,
    theme.theme,
    asset.asset,
    productVariant.productVariant,
    productDynamicBannerEntry.productDynamicBannerEntry,
  ]
)

export const product = defineDocument(
  'product',
  {
    fields: { type: 'productFields' },
  },
  [productFields.productFields]
)
