import { LocaleCodes } from './LocaleCodes'

type Locale = {
  id: LocaleCodes
  title: string
  isDefault?: boolean
} & (
  | {
      overrideCurrency?: false
    }
  | {
      overrideCurrency: true
      currencyCode: string
      currencySymbol: string
      currencySymbolNative: string
    }
)

export const defaultLocale: Locale = {
  id: LocaleCodes.EN_GB,
  title: 'Rest of World',
  isDefault: true,
}

export const Locales: Locale[] = [
  defaultLocale,
  {
    id: LocaleCodes.EN_US,
    title: 'United States',
    overrideCurrency: true,
    currencyCode: 'USD',
    currencySymbol: '$',
    currencySymbolNative: '$',
  },
  {
    id: LocaleCodes.EN_CA,
    title: 'Canada',
    overrideCurrency: true,
    currencyCode: 'CAD',
    currencySymbol: '$',
    currencySymbolNative: '$',
  },
]

export const defaultCountryCode = 'GB'
