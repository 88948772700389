import { IconNames } from '@heights/heights-icons'
import { defineDocument, defineObject } from 'sanity-typed-queries'
import { asset } from './asset'
import { ctaAction } from './ctaAction'
import { JsonType } from './JsonType'
import { ReferenceType } from './ReferenceType'

export type TextItemTypeStub = {
  _type: 'textItem'
  _id: string
  _rev: string
  fields: {
    reference?: string
    image?: ReferenceType | typeof asset.asset
    mobileImage?: ReferenceType | typeof asset.asset
    label?: string
    title?: string
    authorImage?: ReferenceType | typeof asset.asset
    author?: string
    plainText?: string
    content?: JsonType
    numberValue?: number
    textlistValue?: string[]
    ctaAction?: ReferenceType | typeof ctaAction.ctaAction
    icon?: IconNames
    components?: ReferenceType[] | TextItemTypeStub[]
  }
}

const textItemFields = defineObject(
  'textItemFields',
  {
    reference: { type: 'string' },
    image: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    mobileImage: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    label: { type: 'string' },
    title: { type: 'string' },
    authorImage: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    author: { type: 'string' },
    plainText: { type: 'text' },
    content: { type: 'json' },
    numberValue: { type: 'number' },
    textlistValue: { type: 'array', of: [{ type: 'string' }] },
    ctaAction: {
      type: 'reference',
      to: [{ type: 'ctaAction' }],
    },
    icon: { type: 'string', options: { list: Object.values(IconNames) } },
    components: {
      type: 'array',
      of: [
        {
          type: 'reference',
          to: [{ type: 'textItem' }],
        },
      ],
    },
  },
  [{} as JsonType, {} as TextItemTypeStub, asset.asset, ctaAction.ctaAction]
)

export const textItem = defineDocument(
  'textItem',
  {
    fields: {
      type: 'textItemFields',
    },
  },
  [textItemFields.textItemFields]
)
