import cookies from 'nookies'
import { transformLinkHref } from '../transformLinkHref'

export const redirectToLocale = (locale: string) => {
  cookies.set(null, 'locale', locale, {
    path: '/',
    maxAge: 365 * 24 * 3600,
  })
  const url = transformLinkHref(window.location.pathname, locale)
  if (window.location.pathname !== url) {
    window.location.assign(url)
  }
}
