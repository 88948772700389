import dayjs from 'dayjs'
import { createCookiesSignal } from './createCookiesSignal'
import { createPersistedQueryParamsSignal } from './createPersistedQueryParamsSignal'
import { computed } from '@preact/signals-react'
import { PersistedQueryKeys } from '@/constants'

const cookiesSignal = createCookiesSignal()
const queryParamsSignal = createPersistedQueryParamsSignal(
  ...PersistedQueryKeys
)
const fbParamsSignal = computed(() => {
  const queryParams = queryParamsSignal.value
  const cookies = cookiesSignal.value

  const fbclid =
    typeof queryParams.fbclid === 'string' ? queryParams.fbclid : null
  return {
    fbc:
      cookies._fbc ?? (fbclid ? `fb.1.${dayjs().unix()}.${fbclid}` : undefined),
    fbp: cookies._fbp,
  }
})

export function createFbParamsSignal() {
  return fbParamsSignal
}
