import { defineDocument, defineObject } from 'sanity-typed-queries'

const nutrientServingFields = defineObject('nutrientServingFields', {
  reference: { type: 'string' },
  servingLabel: { type: 'string' },
  servingValue: { type: 'string' },
})

export const nutrientServing = defineDocument(
  'nutrientServing',
  {
    fields: {
      type: 'nutrientServingFields',
    },
  },
  [nutrientServingFields.nutrientServingFields]
)
