import { defineDocument, defineObject } from 'sanity-typed-queries'

type PricingMapType = {
  _type: 'shopifyVariantPricingMap'
} & Record<string, number>

export const shopifyImage = defineObject('shopifyImage', {
  src: { type: 'string' },
  width: { type: 'number' },
  height: { type: 'number' },
})

export const shopifyVariantPricing = defineDocument(
  'shopifyVariantPricing',
  {
    variantId: { type: 'number' },
    featuredImage: { type: 'shopifyImage' },
    prices: { type: 'shopifyVariantPricingMap' },
    compareAtPrices: { type: 'shopifyVariantPricingMap' },
  },
  [{} as PricingMapType, shopifyImage.shopifyImage]
)
