import { defineDocument, defineObject } from 'sanity-typed-queries'
import { ShallowArticleTypeStub } from './article'
import { asset } from './asset'
import { ReferenceType } from './ReferenceType'

type NutrientTypeStub = {
  _type: 'nutrient'
  _id: string
  _rev: string
  fields: {
    reference?: string
    name?: string
    compound?: string
    note?: string
    description?: string
    image?: ReferenceType | typeof asset.asset
    dailyServingMg?: number
    nrv?: number
    subNutrient?: ReferenceType | NutrientTypeStub
  }
}

const nutrientFields = defineObject(
  'nutrientFields',
  {
    reference: { type: 'string' },
    name: { type: 'string' },
    compound: { type: 'string' },
    note: { type: 'string' },
    description: { type: 'string' },
    image: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    dailyServingMg: {
      type: 'number',
    },
    nrv: {
      type: 'number',
    },
    subNutrient: {
      type: 'reference',
      to: [{ type: 'nutrient' }],
    },
    learnMoreArticle: {
      type: 'reference',
      to: [{ type: 'article' }],
    },
    learnMoreArticleCtaText: {
      type: 'string',
    },
  },
  [asset.asset, {} as NutrientTypeStub, {} as ShallowArticleTypeStub]
)

export const nutrient = defineDocument(
  'nutrient',
  {
    fields: { type: 'nutrientFields' },
  },
  [nutrientFields.nutrientFields]
)
